import axios from '@Services/axios';

export const AGENCY_GET_LOADING = 'agency/AGENCY_GET_LOADING';
export const AGENCY_GET_SUCCESS = 'agency/AGENCY_GET_SUCCESS';
export const AGENCY_GET_ERROR = 'agency/AGENCY_GET_ERROR';


const initialState = {
    agencies: [],
    
    isGetInProgress: false,
    isGetComplete: false,
    
    error: ''
};

export function reduxThunkReducer(state = initialState, action) {
    switch (action.type) {
        
        case AGENCY_GET_LOADING: {
            return {
                ...state,
                isGetInProgress: true,
                isGetComplete: false,
                error: '',
            };
        }
        case AGENCY_GET_SUCCESS: {
            return {
                ...state,
                isGetInProgress: false,
                isGetComplete: true,
                agencies: action?.agencies || [],
            };
        }
        case AGENCY_GET_ERROR: {
            return {
                ...state,
                isGetInProgress: false,
                isGetComplete: false,
                error: action?.error || '',
            };
        }
        default: {
            return {...state};
        }
    }
}

////////////////////////////////////////////////////////////////////
const URL_API = process.env.REACT_APP_API_ENDPOINT;

export const getAgencies = (allElements) => {
  return (dispatch, getState) => {
    const state = getState();
    
    dispatch({ 
      type: AGENCY_GET_LOADING
    });
    axios.post(`${URL_API}/listeAgences`,
      {}).then(
      response => {
        console.log("response LISTE AGENCE", response?.data?.données);

        if (response?.data?.données) {
          const data = response?.data?.données;
          const agencies = [];
          if (allElements) {
            agencies.push({value: 0, label: 'Toutes les agences'});
          }
          data.map((oneTitle, index) => {
            agencies.push({value: oneTitle?.id || 0, label: oneTitle?.libellé || ''});
          });
          dispatch({ 
            type: AGENCY_GET_SUCCESS,
            agencies: agencies
          });
        } else {
          dispatch({ type: AGENCY_GET_ERROR, error: "Erreur lors de la récupération de la liste des agences" });
        }
    },
    error => {
      dispatch({ type: AGENCY_GET_ERROR, error: error?.response?.data });
    });
  };
};

