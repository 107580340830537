import axios from '@Services/axios';

export const CLIENT_GET_LOADING = 'client/CLIENT_GET_LOADING';
export const CLIENT_GET_SUCCESS = 'client/CLIENT_GET_SUCCESS';
export const CLIENT_GET_ERROR = 'client/CLIENT_GET_ERROR';

const initialState = {
    clients: [],
    
    isGetInProgress: false,
    isGetComplete: false,
    
    error: ''
};

export function reduxThunkReducer(state = initialState, action) {
    switch (action.type) {
        
        case CLIENT_GET_LOADING: {
            return {
                ...state,
                isGetInProgress: true,
                isGetComplete: false,
                clients: [],
                error: '',
            };
        }
        case CLIENT_GET_SUCCESS: {
            return {
                ...state,
                isGetInProgress: false,
                isGetComplete: true,
                clients: action?.clients || [],
            };
        }
        case CLIENT_GET_ERROR: {
            return {
                ...state,
                isGetInProgress: false,
                isGetComplete: false,
                error: action?.error || '',
            };
        }
        default: {
            return {...state};
        }
    }
}

////////////////////////////////////////////////////////////////////
const URL_API = process.env.REACT_APP_API_ENDPOINT;

export const getClients = (lastname) => {
  return (dispatch, getState) => {
    const state = getState();
    
    dispatch({ 
      type: CLIENT_GET_LOADING
    });

    if (!lastname || lastname.length < 4) {
        return;
    }
    axios.post(`${URL_API}/listeNomsClients`,
      {
        nom: lastname,
        prenom: ''
      }).then(
      response => {
        console.log("response LISTE CLIENTS", response?.data?.données);

        if (response?.data?.données) {
          const data = response?.data?.données;
          dispatch({ 
            type: CLIENT_GET_SUCCESS,
            clients: data
          });
        } else {
          dispatch({ type: CLIENT_GET_ERROR, error: "Erreur lors de la récupération de la liste des clients" });
        }
    },
    error => {
      dispatch({ type: CLIENT_GET_ERROR, error: error?.response?.data });
    });
  };
};