import { useEffect } from 'react';
import '@Css/App.css';
import { Provider } from 'react-redux';
import { MemoryRouter as Router } from "react-router-dom";
import { store } from '@Store';
import HandleRoute from '@Route';
import Toast from'@Toast';

function App() {

  return (
    <Provider store={store}>
        <div className="App">
            <Router>
              <Toast />
              <HandleRoute />
            </Router>
        </div>
    </Provider>
  );
}

export default App;
