import React from 'react';
import "react-datepicker/dist/react-datepicker.css";

import { useController, useFormContext } from 'react-hook-form';
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import fr from 'date-fns/locale/fr';
import colors from '@Constants/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
registerLocale('fr', fr);

const Div = styled.div`
  position: relative;
  margin-bottom: 20px;
  margin-top: 10px;
`;

const Wrapper = styled.div`
  display: flex;
`;

const Label = styled.div`
  font-size: 14px;
  color: ${colors.PRIMARY};
  font-weight: bold;
  text-align: left !important;
`;

const SpanError = styled.div`
  color: ${colors.RED};
  font-size: 14px;
  font-weight: normal;
  text-align: left;
`;

const DateCustom = styled(DatePicker)`
  font-size: 16px;
  width: ${props => props.addErase?'310px':'350px'};
  min-height: 38px;
  border: 1px solid ${colors.GREY};
  border-radius: 5px;
  padding-left: 5px;
  &:focus {
    border-color: ${colors.TERTIARY};
    border-width: 2px;
    outline: none;
  }
`;

const IconAction = styled.div`
  width: 38px;
  height: 38px;
  border: 1px solid ${colors.GREY};
  border-radius: 5px;
  margin-left: 2px;
  &:focus {
    border-color: ${colors.TERTIARY};
    border-width: 2px;
    outline: none;
  }
`;

const FontAwesome = styled(FontAwesomeIcon)`
  color: grey;
  padding-top: 2px;
`;

function ControlledDate(props) {
    const formContext = useFormContext();
    const { formState } = formContext;

    const {
        id,
        name,
        label,
        rules,
        defaultValue,
        choiceMonthOnly,
        endPeriod=false,
        minDate=null,
        onErase=null,
        refs,
        } = props;

    const { field } = useController({ name, rules, defaultValue });

    const hasError = Boolean(formState?.errors[name]);

    return (
      <Div>
        {label?.length > 0 && <Label htmlFor={name}>{label}</Label>}
        <Wrapper>
          <DateCustom
            locale="fr"
            ref={refs}
            id={id}
            name={name}
            dateFormat="MMMM yyyy"
            showMonthYearPicker={choiceMonthOnly}
            minDate={minDate}
            withPortal
            selected={field.value}
            onChange={(val) => {
              const dt = endPeriod ? moment(val).endOf('month').toDate() : val;
              console.log("field?.onChange", dt);
              field?.onChange(dt);
            }}
            addErase={onErase !== null}
          />
          {onErase &&
            <IconAction onClick={onErase}>
              <FontAwesome
                  icon={faEraser}
                  size="2x"/>
            </IconAction>}
          {hasError && (<SpanError>{formState.errors[name].message || ''}</SpanError>)}
        </Wrapper>
      </Div>
    );
}

function RDateRange(props) {

  const {
    name,
    setFormError
  } = props;

  const formContext = useFormContext();

  // Placeholder until input name is initialized
  if (!formContext || !name) {
    //const msg = !formContext ? "TextInput must be wrapped by the FormProvider" : "Name must be defined";
    setFormError(true);
    return null;
  }

  return <ControlledDate {...props} />;

};

export default RDateRange;