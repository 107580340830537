import React from 'react';
import "react-datepicker/dist/react-datepicker.css";

import { useController, useFormContext } from 'react-hook-form';
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import fr from 'date-fns/locale/fr';
import colors from '@Constants/colors';
registerLocale('fr', fr);



const Wrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
  margin-top: 10px;
`;

const Label = styled.div`
  font-size: 14px;
  color: ${colors.PRIMARY};
  font-weight: bold;
  text-align: left !important;
`;

const SpanError = styled.div`
  color: ${colors.RED};
  font-size: 14px;
  font-weight: normal;
  text-align: left;
`;

const DateCustom = styled(DatePicker)`
  font-size: 16px;
  width: 350px;
  min-height: 38px;
  border: 1px solid ${colors.GREY};
  border-radius: 5px;
  padding-left: 5px;
  &:focus {
    border-color: ${colors.TERTIARY};
    border-width: 2px;
    outline: none;
  }
`;

function ControlledDate(props) {
    const formContext = useFormContext();
    const { formState } = formContext;

    const {
        id,
        name,
        label,
        rules,
        defaultValue,
        refs,
        } = props;

    const { field } = useController({ name, rules, defaultValue });

    const hasError = Boolean(formState?.errors[name]);

    return (
      <Wrapper>
        {label?.length > 0 && <Label htmlFor={name}>{label}</Label>}
        <DateCustom
          locale="fr"
          ref={refs}
          id={id}
          name={name}
          showTimeSelect
          dateFormat="d MMMM yyyy à HH'h'mm"
          timeIntervals={15}
          withPortal
          selected={field.value}
          onChange={field?.onChange}
        />
        
        {hasError && (<SpanError>{formState.errors[name].message || ''}</SpanError>)}
      </Wrapper>
    );
}

function RDate(props) {

  const {
    name,
    setFormError
  } = props;

  const formContext = useFormContext();

  // Placeholder until input name is initialized
  if (!formContext || !name) {
    //const msg = !formContext ? "TextInput must be wrapped by the FormProvider" : "Name must be defined";
    setFormError(true);
    return null;
  }

  return <ControlledDate {...props} />;

};

export default RDate;