import L from 'leaflet';

const markerStatus = [
    L.icon({ iconUrl: "./assets/map/marker-perso-32.png", iconAnchor: [16,32], label: 'Ma position' }),   // My position
    L.icon({ iconUrl: "./assets/map/marker-white-32.png", iconAnchor: [16,32], label: 'Prospect' }),   // "etat":1 --> marker-white-32
    L.icon({ iconUrl: "./assets/map/marker-pink-32.png", iconAnchor: [16,32], label: 'R1 annulé' }),    // "etat":2 --> marker-pink-32
    L.icon({ iconUrl: "./assets/map/marker-orange-32.png", iconAnchor: [16,32], label: 'R1 confirmé' }),  // "etat":3 --> marker-orange-32
    L.icon({ iconUrl: "./assets/map/marker-yellow-32.png", iconAnchor: [16,32], label: 'Renseigné en attente' }),  // "etat":4 --> marker-yellow-32
    L.icon({ iconUrl: "./assets/map/marker-green-32.png", iconAnchor: [16,32], label: 'Renseigné vendu' }),   // "etat":5 --> marker-green-32
    L.icon({ iconUrl: "./assets/map/marker-red-32.png", iconAnchor: [16,32], label: 'Renseigné perdu' }),     // "etat":6 --> marker-red-32
    L.icon({ iconUrl: "./assets/map/marker-black-32.png", iconAnchor: [16,32], label: 'Annulation client' })    // "etat":7 --> marker-black-32
];

export default markerStatus;