import { useController, useFormContext } from 'react-hook-form';
import styled from "styled-components";
import colors from '@Constants/colors';

const Div = styled.div`
  position: relative;
  margin-bottom: 20px;
  margin-top: 10px;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CustomTextarea = styled.textarea.attrs(() => ({
  required: true
}))`
  font-size: 16px;
  padding: 7px 10px 10px 5px;
  display: block;
  width: 350px;
  border: 1px solid ${colors.GREY};
  border-radius: 5px;
  &:focus {
    border-color: ${colors.TERTIARY};
    border-width: 2px;
    outline: none;
  }
`;

const Label = styled.div`
  font-size: 14px;
  color: ${colors.PRIMARY};
  font-weight: bold;
  text-align: left !important;
`;

const SpanError = styled.div`
  color: ${colors.RED};
  font-size: 14px;
  font-weight: normal;
  text-align: left;
`;


function ControlledTextarea(props) {
    const formContext = useFormContext();
    const { formState } = formContext;

    const {
        id,
        name,
        label,
        rules,
        defaultValue,
        refs,
        rows
        } = props;

    const { field } = useController({ name, rules, defaultValue });

    const hasError = Boolean(formState?.errors[name]);
    return (
      <Div>
        <Wrapper>
          {label?.length > 0 &&
            <Label>{label}</Label>}
          <CustomTextarea
            id={id}
            name={name}
            value={field?.value || defaultValue}
            onChange={field.onChange}
            rows={rows}
            ref={refs}
          />
        </Wrapper>
          
        {hasError && (<SpanError>{formState.errors[name].message || ''}</SpanError>)}
      </Div>
    );
}

function RTextarea(props) {

  const {
    name,
    setFormError
  } = props;

  const formContext = useFormContext();

  // Placeholder until input name is initialized
  if (!formContext || !name) {
    //const msg = !formContext ? "TextInput must be wrapped by the FormProvider" : "Name must be defined";
    setFormError(true);
    return null;
  }

  return <ControlledTextarea {...props} />;

};

export default RTextarea;