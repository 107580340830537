import axios from '@Services/axios';

export const SOURCE_GET_LOADING = 'source/SOURCE_GET_LOADING';
export const SOURCE_GET_SUCCESS = 'source/SOURCE_GET_SUCCESS';
export const SOURCE_GET_ERROR = 'source/SOURCE_GET_ERROR';


const initialState = {
    sources: [],
    
    isGetInProgress: false,
    isGetComplete: false,
    
    error: ''
};

export function reduxThunkReducer(state = initialState, action) {
    switch (action.type) {
        
        case SOURCE_GET_LOADING: {
            return {
                ...state,
                isGetInProgress: true,
                isGetComplete: false,
                error: '',
            };
        }
        case SOURCE_GET_SUCCESS: {
            return {
                ...state,
                isGetInProgress: false,
                isGetComplete: true,
                sources: action?.sources || [],
            };
        }
        case SOURCE_GET_ERROR: {
            return {
                ...state,
                isGetInProgress: false,
                isGetComplete: false,
                error: action?.error || '',
            };
        }
        default: {
            return {...state};
        }
    }
}

////////////////////////////////////////////////////////////////////
const URL_API = process.env.REACT_APP_API_ENDPOINT;

export const getSources = () => {
  return (dispatch, getState) => {
    const state = getState();
    
    dispatch({ 
      type: SOURCE_GET_LOADING
    });
    axios.post(`${URL_API}/listeSources`,
      {}).then(
      response => {
        console.log("response LISTE SOURCE", response?.data?.données);
        if (response?.data?.données) {
          const data = response?.data?.données;
          const sources = [];
          data.map((oneSource, index) => {
            sources.push({value: oneSource?.id || 0, label: oneSource?.libellé || ''});
          });
          dispatch({ 
            type: SOURCE_GET_SUCCESS,
            sources: sources || []
          });
        } else {
          dispatch({ type: SOURCE_GET_ERROR, error: "Erreur lors de la récupération de la liste des sources" });
        }
    },
    error => {
      dispatch({ type: SOURCE_GET_ERROR, error: error?.response?.data });
    });
  };
};

