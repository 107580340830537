const colors = {
    PRIMARY : process.env.REACT_APP_COLOR_PRIMARY,
    SECONDARY : process.env.REACT_APP_COLOR_SECONDARY,
    TERTIARY : process.env.REACT_APP_COLOR_TERTIARY,
    BLUE: '#084298',
    RED: '#E60060',
    GREEN: '#A3C08B',
    GREY: '#606060',
    WHITE: '#FFFFFF',
    BLACK: '#000000'
}

export default colors;