import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ModalFullScreen({
    title,
    content,
    show,
    showFooter=true,
    onClose,
    onGo,
    labelOnGo
}) {

    return (
    <>
      <Modal show={show} fullscreen={true} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        {showFooter && <Modal.Footer>
            {labelOnGo && onGo && <Button variant="success" onClick={onGo}>{labelOnGo || 'OK'}</Button>}
            <Button variant="danger" onClick={onClose}>Fermer</Button>
        </Modal.Footer>}
      </Modal>
    </>);
}

export default ModalFullScreen;