import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import Menu from '@Menu';
import RInput from '@Components/form/input';
import RSelect from '@Components/form/select';
import Button from 'react-bootstrap/Button';
import styled from "styled-components";
import { currentNewMeetingUpdate, meetingUpdateStepCurrentFinished } from '@Store/Meeting';
import { getCities } from '@Store/City';
import { getSources } from '@Store/Source';
import ModalFullScreen from '@Components/modal/fullScreen';
import colors from '@Constants/colors';

import Form from 'react-bootstrap/Form';

const Title = styled.h1`
    color: ${colors.BLACK};
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 10px 0px 10px 0px;
`;

const Hline = styled.div`
    width: 100%;
    height: 1px;
    background: ${colors.BLACK};
`;

const SubTitle = styled.h3`
    color: grey;
    font-size: 14px;
    font-weight: bold;
    margin: 0px 10px 10px 10px;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Btns = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between; 
`;

const BtnOutline = styled(Button)`
  border-color: ${colors.SECONDARY};
  color: ${colors.SECONDARY};
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 0;
  font-weight: bold;
  text-transform: uppercase;
  width: 174px;
  border-radius: 5px;
  height: 38px;
  &:active {
    border-color: ${colors.TERTIARY};
    background-color: transparent;
  }
`;

const Submit = styled.input.attrs({ type: 'submit' })`
  background: ${colors.SECONDARY};
  color: ${colors.WHITE};
  cursor: pointer;
  margin-bottom: 0;
  font-weight: bold;
  text-transform: uppercase;
  width: 174px;
  border-radius: 5px;
  height: 38px;
  border-color: transparent;
  box-shadow: 0px;
  outline: none;
  transition: 0.15s;
  text-align: center;
  text-transform: uppercase;
  &:active {
    background-color: ${colors.TERTIARY};
  }
`;

const ListContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const ItemClient = styled.div`
    border-color: ${colors.BLACK};
    border:1px solid;
    border-radius: 5px;
    padding: 5px 5px 5px 5px;
    margin-bottom: 5px;
`;

const ItemClientName = styled.div`
    font-weight: bold;
`;

const SpanError = styled.div`
  color: ${colors.RED};
  font-size: 14px;
  font-weight: normal;
  text-align: left;
`;

function NewMeetingStepTwo() {
    const dispatch = useDispatch();
    const [setError] = useState(false);
    const [listCityShow, setListCityShow]= useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const isBack = location?.state?.back;

    const selectSource = useRef(null);
    const inputAddress = useRef(null);
    const inputZip = useRef(null);
    const inputCity = useRef(null);
    const inputPhone = useRef(null);
    const inputMobile = useRef(null);
    const inputEmail = useRef(null);
    const inputPrecisionSource = useRef(null);
    
    const {...methods} = useForm({mode: 'onChange'})

    const user = useSelector(state => state?.user);
    const meeting = useSelector(state => state?.meeting);
    const cities = useSelector(state => state?.city.cities);
    const sources = useSelector(state => state?.source.sources);

    //console.log("meeting", meeting);

    useEffect(() => {
        if (!isBack) {
            dispatch(getSources());
            methods.resetField("adresse");
            methods.resetField("codePostal");
            methods.resetField("ville");
            methods.resetField("telephone");
            methods.resetField("mobile");
            methods.resetField("email");
            methods.resetField("source");
            methods.resetField("precisionSource"); 
        }
            
        if (meeting?.current?.adresse?.length > 0) {
            methods.setValue('adresse', meeting?.current?.adresse);
        }
        if (meeting?.current?.codePostal?.length > 0) {
            methods.setValue('codePostal', meeting?.current?.codePostal);
        }
        if (meeting?.current?.ville?.length > 0) {
            methods.setValue('ville', meeting?.current?.ville);
        }
        if (meeting?.current?.telephone?.length > 0) {
            methods.setValue('telephone', meeting?.current?.telephone);
        }
        if (meeting?.current?.mobile?.length > 0) {
            methods.setValue('mobile', meeting?.current?.mobile);
        }
        if (meeting?.current?.email?.length > 0) {
            methods.setValue('email', meeting?.current?.email);
        }
        if (meeting?.current?.precisionSource?.length > 0) {
            methods.setValue('precisionSource', meeting?.current?.precisionSource);
        }
    }, []);

    useEffect(() => {
        if (!meeting?.updateLoading && meeting?.updateComplete) {
            navigate("/newMeeting-step03");
            dispatch(meetingUpdateStepCurrentFinished());
        }
    }, [meeting]);

    const onSubmit = (data) => {
        //console.log("onSubmit", data);

        if (data?.telephone?.length === 0 && data?.mobile?.length === 0) {
            methods.setError('telephone', { type: 'custom', message: 'Vous devez renseigner un des 2 numéros de téléphone' });
            return;
        }
        dispatch(currentNewMeetingUpdate(data));
    }

    const onError = (errors, e) => {
        return console.log({errors});
    }

    function CityOneItem({data, index, zip}) {
        return (
            <ItemClient key={index} onClick={(e) => {
                //console.log("onClick", data);
                methods.setValue('ville', data?.label);
                dispatch(getCities(zip));
                /* ... */

                setListCityShow(false);
            }}>
                <ItemClientName>{zip || ''} {data?.label || ''}</ItemClientName>
            </ItemClient>
        );
    }

    function CitiesList () {
        return (
            <>
            {cities?.length === 0 &&
                <ListContent>
                    <Alert variant="primary">
                        <Alert.Heading>Résultat de la recherche 😳</Alert.Heading>
                        <p>Il n'y a pas d'occurence trouvée !</p>
                    </Alert>
                </ListContent>
            }
            {cities?.length > 0 &&
                <ListContent>
                    {cities.map(function(item, ix){
                        return <CityOneItem data={item} index={item.value} zip={methods.getValues("codePostal")}/>
                    })}
                </ListContent>}
            </>
        );
    }

    return (
        <>
        <Menu />
        <Wrapper>
            <FormProvider {...methods}>
                <Form>
                    <Title>Ajouter un contact</Title>
                    <SubTitle>Étape 2/3</SubTitle>
                    <Form.Group className="mb-3" controlId="formBasicId">
                        <ModalFullScreen 
                            show={listCityShow}
                            title="Liste des villes"
                            content={<CitiesList />}
                            onClose={() => setListCityShow(false)}
                        />
                        <RInput
                            refs={inputAddress}
                            type="text"
                            id="address"
                            name="adresse"
                            label="Adresse"
                            defaultValue=""
                            rules={{
                                required: 'L\'adresse est obligatoire'
                            }}
                            setFormError={setError}/>
                        <RInput
                            refs={inputZip}
                            type="text"
                            id="zip"
                            name="codePostal"
                            label="Code postal"
                            defaultValue=""
                            nbRecords={cities?.length || 0}
                            addList={cities?.length > 0}
                            onClickList={() => {
                                setListCityShow(true);
                            }}
                            onValueChanged={(e) => {
                                dispatch(getCities(e.target.value));
                            }}
                            rules={{
                                required: 'Le code postal est obligatoire',
                                maxLength: {
                                    value: 5,
                                    message: "Le code postal est limité à 5 caractères"
                                }
                            }}
                            setFormError={setError}/>
                        <RInput
                            refs={inputCity}
                            type="text"
                            id="city"
                            name="ville"
                            label="Ville"
                            defaultValue=""
                            rules={{
                                required: 'La ville est obligatoire',
                                maxLength: {
                                    value: 30,
                                    message: "Le nom de la ville est limité à 30 caractères"
                                }
                            }}
                            setFormError={setError}/>
                        <RInput
                            refs={inputPhone}
                            type="tel"
                            id="phone"
                            name="telephone"
                            label="Téléphone"
                            defaultValue=""
                            rules={{
                                maxLength: {
                                    value: 20,
                                    message: "Le numéro de téléphone fixe est limité à 20 caractères"
                                }
                            }}
                            setFormError={setError}/>
                        <RInput
                            refs={inputMobile}
                            type="tel"
                            id="mobile"
                            name="mobile"
                            label="Mobile"
                            defaultValue=""
                            rules={{
                                maxLength: {
                                    value: 20,
                                    message: "Le numéro du mobile est limité à 20 caractères"
                                }
                            }}
                            setFormError={setError}/>
                        <RInput
                            refs={inputEmail}
                            type="email"
                            id="email"
                            name="email"
                            label="Email"
                            defaultValue=""
                            rules={{
                                maxLength: {
                                    value: 120,
                                    message: "L'email est limité à 120 caractères"
                                },
                                pattern: {
                                    value: /\b[\w\\.+-]+@[\w\\.-]+\.\w{2,4}\b/,
                                    message: 'L\'email doit être formaté comme: nom@mail.com',
                                },
                            }}
                            setFormError={setError}/>
                        <Hline />
                        <RSelect
                            refs={selectSource}
                            id="source"
                            name="source"
                            label="Source"
                            defaultValue={meeting?.current?.source?.label?.length > 0 ? meeting?.current?.source : {}}
                            data={sources}
                            rules={{
                                required: 'La source est obligatoire'
                            }}
                            setFormError={setError}/>
                         <RInput
                            refs={inputPrecisionSource}
                            type="text"
                            id="precisionSource"
                            name="precisionSource"
                            label="Précision source"
                            defaultValue=""
                            rules={{
                                maxLength: {
                                    value: 120,
                                    message: "La précision de la source est limitée à 120 caractères"
                                }
                            }}
                            setFormError={setError}/>
                    </Form.Group>
                    <Btns>
                        <BtnOutline
                            onClick={()=>{navigate("/newMeeting-step01", {state: {back: true}})}}>
                            Précédent
                        </BtnOutline>
                        <Submit value="Suivant" onClick={methods.handleSubmit(onSubmit, onError)}/>
                    </Btns>
                </Form>
            </FormProvider>
        </Wrapper>
        </>
    );
}

export default NewMeetingStepTwo;