import axios from '@Services/axios';

export const COMMERCIAL_GET_LOADING = 'commercial/COMMERCIAL_GET_LOADING';
export const COMMERCIAL_GET_SUCCESS = 'commercial/COMMERCIAL_GET_SUCCESS';
export const COMMERCIAL_GET_ERROR = 'commercial/COMMERCIAL_GET_ERROR';

const initialState = {
    commercials: [],
    
    isGetInProgress: false,
    isGetComplete: false,
    
    error: ''
};

export function reduxThunkReducer(state = initialState, action) {
    switch (action.type) {
        
        case COMMERCIAL_GET_LOADING: {
            return {
                ...state,
                isGetInProgress: true,
                isGetComplete: false,
                error: '',
            };
        }
        case COMMERCIAL_GET_SUCCESS: {
            return {
                ...state,
                isGetInProgress: false,
                isGetComplete: true,
                commercials: action?.commercials || [],
            };
        }
        case COMMERCIAL_GET_ERROR: {
            return {
                ...state,
                isGetInProgress: false,
                isGetComplete: false,
                error: action?.error || '',
            };
        }
        default: {
            return {...state};
        }
    }
}

////////////////////////////////////////////////////////////////////
const URL_API = process.env.REACT_APP_API_ENDPOINT;

export const getCommercials = (allElements) => {
  return (dispatch, getState) => {
    const state = getState();
    
    dispatch({ 
      type: COMMERCIAL_GET_LOADING
    });
    axios.post(`${URL_API}/listeCommerciaux`,
      {}).then(
      response => {
        console.log("response LISTE COMMERCIAUX", response?.data?.données);

        if (response?.data?.données) {
          const data = response?.data?.données;
          const commercials = [];
          if (allElements) {
            commercials.push({value: 0, label: 'Tous les commerciaux'});
          }
          data.map((oneCommercial, index) => {
            commercials.push({value: oneCommercial?.id || 0, label: oneCommercial?.nom || ''});
          });
          dispatch({ 
            type: COMMERCIAL_GET_SUCCESS,
            commercials: commercials
          });
        } else {
          dispatch({ type: COMMERCIAL_GET_ERROR, error: "Erreur lors de la récupération de la liste des commerciaux" });
        }
    },
    error => {
      dispatch({ type: COMMERCIAL_GET_ERROR, error: error?.response?.data });
    });
  };
};

