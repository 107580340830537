import { useController, useFormContext } from 'react-hook-form';
import styled from "styled-components";
import Form from 'react-bootstrap/Form';
import colors from '@Constants/colors';

const Div = styled.div`
  position: relative;
  margin-bottom: 20px;
  margin-top: 10px;
`;

const Check = styled(Form.Check)`
  .form-check-input:checked {
    background-color: ${colors.SECONDARY};
    border-color: ${colors.SECONDARY};
  }
  .form-check-input {
    height: 23px;
    width: 46px;
  }
  label {
    display: inline-block;
    margin-top: 5px;
    margin-left: 10px;
  }
`;

const Wrapper = styled.div`
  display: flex;
`;

const Label = styled.div`
  font-size: 14px;
  color: ${colors.PRIMARY};
  font-weight: bold;
  text-align: left !important;
`;

const SpanError = styled.div`
  color: ${colors.RED};
  font-size: 14px;
  font-weight: normal;
  text-align: left;
`;

function ControlledCheck(props) {
    const formContext = useFormContext();
    const { formState } = formContext;

    const {
        id,
        name,
        label,
        rules,
        defaultValue,
        refs
        } = props;

    const { field } = useController({ name, rules, defaultValue });

    const hasError = Boolean(formState?.errors[name]);
    return (
      <Div>
        <Wrapper>
          <Check
            //reverse
            ref={refs} 
            type="switch"
            id={id}
            name={name}
            label={<Label>{label}</Label>}
            value={field?.value || defaultValue}
            onChange={field.onChange}
          />
        </Wrapper>
          
        {hasError && (<SpanError>{formState.errors[name].message || ''}</SpanError>)}
      </Div>
    );
}

function RCheck(props) {

  const {
    name,
    setFormError
  } = props;

  const formContext = useFormContext();

  // Placeholder until input name is initialized
  if (!formContext || !name) {
    //const msg = !formContext ? "TextInput must be wrapped by the FormProvider" : "Name must be defined";
    setFormError(true);
    return null;
  }

  return <ControlledCheck {...props} />;

};

export default RCheck;