import { useController, useFormContext } from 'react-hook-form';
import styled from "styled-components";
import colors from '@Constants/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';


const Div = styled.div`
  flex: 1;
  position: relative;
  margin-bottom: 5px;
  margin-top: 5px;
`;

const Wrapper = styled.div`
  //display: flex;
`;

const CheckBtn = styled.div`
  width: 165px;
  height: 85px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.color};
  border-color: ${props => props.borderColor};
  border-style: solid;
  border-width: ${props => props.checked ? '3px':'1px'};
  border-radius: 5px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer; 
`;

const Label = styled.div`
  font-size: 14px;
  color: ${props => props.textColor};
`;

const IconMarker = styled.div`
    background-image: url(${props => props.iconMarker});
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
`;

const SpanError = styled.div`
  color: ${colors.RED};
  font-size: 14px;
  font-weight: normal;
  text-align: left;
`;

const CheckIcon = styled.div`
  position: absolute;
  top: -5px;
  left: -5px;
`;

function ControlledCheck(props) {
    const formContext = useFormContext();
    const { formState } = formContext;
    const {
        name,
        label,
        rules,
        defaultValue,
        color,
        textColor,
        checkColor,
        iconMarker
        } = props;

    const { field } = useController({ name, rules, defaultValue });

    const onClick = (e) => {
      e.target.value = !(field?.value || defaultValue);
      field.onChange(e);
    }

    const hasError = Boolean(formState?.errors[name]);

    console.log("checkMarker", field?.value, defaultValue);

    return (
      <Div>
        <Wrapper>
          <CheckBtn color={color} borderColor={(field?.value || defaultValue)?checkColor:colors.BLACK} checked={field?.value || defaultValue} onClick={onClick}>
          {(field?.value || defaultValue) &&
            <CheckIcon><FontAwesomeIcon icon={faCheckSquare} size="3x" color={checkColor}/></CheckIcon>}
            <IconMarker iconMarker={iconMarker}/>
            <Label textColor={textColor}>{label}</Label>
          </CheckBtn>
        </Wrapper>
          
        {hasError && (<SpanError>{formState.errors[name].message || ''}</SpanError>)}
      </Div>
    );
}

function RCheckMarker(props) {

  const {
    name,
    setFormError
  } = props;

  const formContext = useFormContext();

  // Placeholder until input name is initialized
  if (!formContext || !name) {
    //const msg = !formContext ? "TextInput must be wrapped by the FormProvider" : "Name must be defined";
    setFormError(true);
    return null;
  }

  return <ControlledCheck {...props} />;

};

export default RCheckMarker;