import _ from 'lodash';
import constHistory from '@Constants/history';

export const HISTORIC_SAVE_SUCCESS = 'history/HISTORIC_SAVE_SUCCESS';
export const HISTORIC_SAVE_ERROR = 'history/HISTORIC_SAVE_ERROR';

export const HISTORIC_RESET_SUCCESS = 'history/HISTORIC_RESET_SUCCESS';
export const HISTORIC_RESET_ERROR = 'history/HISTORIC_RESET_ERROR';

export const HISTORIC_DELETE_SUCCESS = 'history/HISTORIC_DELETE_SUCCESS';
export const HISTORIC_DELETE_ERROR = 'history/HISTORIC_DELETE_ERROR';


const initialState = {
    historic: [],
    isAddInProgress: false,
    isAddComplete: false,

    isResetInProgress: false,
    isResetComplete: false,

    isDeleteInProgress: false,
    isDeleteComplete: false,
    
    error: ''
};

export function reduxThunkReducer(state = initialState, action) {
    switch (action.type) {

        case HISTORIC_SAVE_SUCCESS: {
            return {
                ...state,
                isAddInProgress: false,
                isAddComplete: true,
                historic: action?.historic || [],
                error:"",
            };
        }
        case HISTORIC_SAVE_ERROR: {
            return {
                ...state,
                isAddInProgress: false,
                isAddComplete: false,
                error: action?.error || '',
            };
        }
        case HISTORIC_RESET_SUCCESS: {
            return {
                ...state,
                isResetInProgress: false,
                isResetComplete: true,
                historic: action?.historic || [],
                error:"",
            };
        }
        case HISTORIC_RESET_ERROR: {
            return {
                ...state,
                isResetInProgress: false,
                isResetComplete: false,
                error: action?.error || '',
            };
        }
        case HISTORIC_DELETE_SUCCESS: {
            return {
                ...state,
                isDeleteInProgress: false,
                isDeleteComplete: true,
                historic: action?.historic || [],
                error:"",
            };
        }
        case HISTORIC_DELETE_ERROR: {
            return {
                ...state,
                isDeleteInProgress: false,
                isDeleteComplete: false,
                error: action?.error || '',
            };
        }
        default: {
            return {...state};
        }
    }
}

////////////////////////////////////////////////////////////////////
export const addHistoric = (newData) => {
    return (dispatch, getState) => {
        const state = getState();
        const historicCurrent = state?.history?.historic || [];
        const historicNew = [...historicCurrent];

        if (!newData || !newData?.address || !newData?.id || !newData?.lat || !newData?.lng) {
            dispatch({ type: HISTORIC_SAVE_ERROR, error: 'Il manque des paramètres' });
        }
        else {
            var historicIndex = _.findIndex(historicNew, ['address', newData.address]);
            if(historicIndex !== -1){
                historicNew[historicIndex].id = newData.id;
                const tmp = _.orderBy(historicNew, ['id'],['desc']);
                dispatch({ type: HISTORIC_SAVE_SUCCESS, historic:tmp });
            }else if(historicNew.length >= constHistory.NB_ELEMENT_HISTORIC_ADDRESS){
                historicNew.pop();
                historicNew.push(newData);
                const tmp = _.orderBy(historicNew, ['id'],['desc']);
                dispatch({ type: HISTORIC_SAVE_SUCCESS, historic:tmp });
            }else{
                historicNew.push(newData);
                const tmp = _.orderBy(historicNew, ['id'],['desc']);
                dispatch({ type: HISTORIC_SAVE_SUCCESS, historic:tmp });
            }
        }
    };
};

////////////////////////////////////////////////////////////////////
export const resetHistoric = () => {
    return (dispatch) => {
        console.log("=====> Reset historic =====>")
        const historicReset = [];
        dispatch({ 
            type: HISTORIC_RESET_SUCCESS, historic:historicReset });
        };
};

////////////////////////////////////////////////////////////////////
export const deleteHistoric = (id) => {
    return (dispatch, getState) => {
        const state = getState();
        const historicCurrent = state?.history?.historic || [];
        const historicNew = [...historicCurrent];

        if (!id) {
            dispatch({ type: HISTORIC_DELETE_ERROR, error: 'Il manque des paramètres' });
        }
        var historicIndex = _.findIndex(historicNew, ['id', id]);
        if(historicIndex === -1){
            dispatch({ type: HISTORIC_DELETE_ERROR, error: "L'hitorique correspondant à l'id n'existe pas" });
        }else{
            historicNew.splice(historicIndex,1);
            const tmp = _.orderBy(historicNew, ['id'],['desc']);
            dispatch({type: HISTORIC_DELETE_SUCCESS, historic:tmp});
        }
    };
};

