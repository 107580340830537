import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {isMobile} from 'react-device-detect';
import { MapContainer as LeafMap, TileLayer, Marker, Popup, Circle, useMap } from 'react-leaflet';
import { useMapEvent, useMapEvents } from 'react-leaflet/hooks';
import 'leaflet/dist/leaflet.css';
import useOnScreen from '@Hook/useOnScreen';
import distance from '@Utilities/distance';
import Menu from '@Menu';
import { getMarkers } from '@Store/Marker';
import {resetHistoric, addHistoric, deleteHistoric} from '@Store/Historic';
import AlertError from '@Components/alertError';
import styled from "styled-components";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import optionsRadius from '@Constants/optionsRadius';
import RoundIconSearch from '@Components/roundIcon/search';
import RoundIconGeo from '@Components/roundIcon/geo';
import InputFindAddress from '@Components/form/findAddress';
import HistoryAddress from '@Components/form/historyAddress';
import FilterMap from './filter';
import Spinner from '@Components/spinner';
import markerStatus from '@Constants/markerStatus';
import clientStatus from '@Constants/clientStatus';
import colors from '@Constants/colors';
import moment from 'moment';


import { Modal } from 'react-bootstrap';

const Sep = styled.div`
  margin-top: 10px;
`;

const PopupText = styled.div`
  font-size: 12px;
  margin-bottom: 2px;
`;

const DivHeaderMarker = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const DivIconMarker = styled.div`
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 2px;
  width: 45%;
`;

const DivAgencyMarker = styled.div`
  align-items: flex-start;
  font-size: 22px;
  margin-bottom: 2px;
  width: 65%;
`;

const IconMarker = styled.div`
    background-image: url(${props => props.iconMarker});
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
    margin-right: 5px;
`;

const BtnOutline = styled(Button)`
  border-color: ${colors.SECONDARY};
  color: ${colors.SECONDARY};
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 0;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  border-radius: 5px;
  height: 38px;
  &:active {
    border-color: ${colors.TERTIARY};
    background-color: transparent;
  }
`;

const deltaDistance = 50;  // 50 mètres

function Map() {
  const dispatch = useDispatch();

  const refMap = useRef(null);
  const isMapVisible = useOnScreen(refMap);

  const user = useSelector(state => state?.user);
  const [status, setStatus] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const popupisopen = useRef(false);
  const [iconhistoricAddressIsVisible, setIconHistoricAddressIsVisible] = useState(false);
  const [historicAddressIsVisible, setHistoricAddressIsVisible] = useState(false);
  const [findInputIsVisible, setFindInputIsVisible] = useState(false);
  const [findInputValue, setFindInputValue] = useState("");
  const [filter, setFilter] = useState({etats:process.env.REACT_APP_MAP_FILTER_DEFAULT.split(',').map( Number )});
  const [idWatchGeoLoc, setWatchGeoLoc] = useState(-1);
  const [currentPosition, setCurrentPosition] = useState({latitude: user?.latitudeAgence,longitude:user?.longitudeAgence});
  const [watchPositionGiven, setWatchPositionGiven] = useState({latitude: 0, longitude: 0});
  const [radius, setRadius] = useState(optionsRadius[process.env.REACT_APP_MAP_FILTER_RADIUS]);
  const [mapZoom, setMapZoom] = useState(isMobile ? optionsRadius[0].zoomMobile : optionsRadius[0].zoomNoMobile);

  const marker = useSelector(state => state?.marker);
  const markers = marker?.markers || [];
  const history = useSelector(state => state?.history);
  const historic= history?.historic || [];
  //console.log("historic", historic);
  const spinnerVisible = marker.isGetInProgress && !marker.isGetComplete;

  useEffect(() => {
    if (navigator.geolocation) {
      watchPosition();
    }
  }, []);

  useEffect(() => {
    setIconHistoricAddressIsVisible(historic?.length > 0 && !showFilter);
  }, [historic, showFilter]);

  useEffect(() => {
    //console.log("Carto est-elle visible ?", isMapVisible);
    if (currentPosition?.latitude !== 0 && currentPosition?.longitude !== 0 && isMapVisible && !showFilter && !marker?.isGetInProgress) {
      console.log("currentPosition in useEffect", currentPosition);
      dispatch(getMarkers(radius?.value || 250, currentPosition?.latitude, currentPosition?.longitude, filter));
    }
  }, [currentPosition, radius, filter]);

  useEffect(() => {
    console.log("position données:", watchPositionGiven);
    console.log("position actuelle:", currentPosition);
    const dist = distance(watchPositionGiven.latitude, watchPositionGiven.longitude, currentPosition.latitude, currentPosition.longitude) * 1000; // distance en mètres
    console.log("distance d'écart:", dist);
    if (dist > deltaDistance ) {
      console.log("Position is changed !");
      setStatus(null);
      setCurrentPosition({
        latitude: watchPositionGiven.latitude,
        longitude: watchPositionGiven.longitude
      });
    }
  }, [watchPositionGiven]);


  function watchPosition () {
    if (idWatchGeoLoc < 0) {
      const id = navigator.geolocation.watchPosition((pos) => {
        console.log("watchPosition called !", pos, ' - is filter is shown', showFilter);

        if (showFilter || popupisopen.current) {
          return;
        }

        setWatchPositionGiven({
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude
        });
      }, () => {
        setCurrentPosition({latitude: user?.latitudeAgence,longitude:user?.longitudeAgence});
        setStatus("Impossible de récupérer votre position");
      });
      setWatchGeoLoc(id);
    }
  }

  function RenderCurrentPosition () {
    return currentPosition === null ? null :
      (<>
          {currentPosition?.latitude && currentPosition?.longitude &&
            <Marker
              position={[currentPosition?.latitude, currentPosition?.longitude]}
              icon={markerStatus[0]}
            >
              <Popup key={JSON.stringify([1999, mapZoom, currentPosition?.latitude, currentPosition?.longitude])}>
                <Card.Title>Je suis ici !</Card.Title>
              </Popup>
            </Marker>}
        </>);
  }

  function RenderClientsPosition () {

    const popupElRef = useRef(null);

    const closePopups = () => {
      popupElRef.current.close();
    };

      return (
        <>
        {(markers.map((oneMarker, index) =>
            <Marker
              key={JSON.stringify([index, mapZoom, currentPosition?.latitude, currentPosition?.longitude, oneMarker?.latitude, oneMarker?.longitude])}
              position={[oneMarker?.latitude, oneMarker?.longitude]}
              icon={markerStatus[oneMarker.etat]}
              eventHandlers={{
                popupopen: () => {popupisopen.current = true;},
                popupclose: () => {popupisopen.current = false;},
              }}          
            >
              <Popup 
                closeButton={false} 
                ref={popupElRef} 
                key={index}
              >
                <DivHeaderMarker>
                  <DivIconMarker>
                    <IconMarker iconMarker={clientStatus[oneMarker.etat].iconMarker}/> {clientStatus[oneMarker.etat].label || ''}
                  </DivIconMarker>
                  <DivAgencyMarker>
                    <PopupText>{`Agence: ${oneMarker?.nomAgence || ''}`}</PopupText>
                    <PopupText>{`Commercial: ${oneMarker?.nomCommercial || ''}`}</PopupText>
                  </DivAgencyMarker>
                </DivHeaderMarker>
                
                <Sep />
                <Card.Title>{oneMarker?.nom || ''}</Card.Title>
                <Card.Subtitle>{oneMarker?.adresse || ''}</Card.Subtitle>
                {oneMarker?.telephone?.length > 0 && <Card.Subtitle><a href={`tel:${oneMarker.telephone.split('.').join('').split(' ').join('')}`}>{oneMarker?.telephone}</a></Card.Subtitle>}
                {oneMarker?.mobile?.length > 0 && <Card.Subtitle><a href={`tel:${oneMarker.mobile.split('.').join('').split(' ').join('')}`}>{oneMarker.mobile}</a></Card.Subtitle>}
                {oneMarker?.details.length > 0 &&
                <>
                  <Sep />
                  <Card.Subtitle>Historique</Card.Subtitle>
                  {(oneMarker?.details.map((oneDetail, ix) =>
                    <PopupText>{`Le ${oneDetail?.date || ''} - ${oneDetail?.famille || ''} (${oneDetail?.quantite || '?'})`}</PopupText>
                  ))}
                </>}
                {oneMarker?.resteAFaire?.length > 0 &&
                <>
                  <Sep />
                  <Card.Subtitle>Reste à faire</Card.Subtitle>
                  {(oneMarker?.resteAFaire.map((oneResteAFaire, ix) =>
                    <PopupText>{`${oneResteAFaire?.famille || ''} - ${oneResteAFaire?.materiau || ''} (${oneResteAFaire?.quantite || '?'})`}</PopupText>
                  ))}
                </>}
                <Sep />
                <BtnOutline onClick={closePopups}> Fermer </BtnOutline>
              </Popup>
            </Marker>
        ))}
        </>);
  }

  function RenderDrawCircle () {
    if (radius?.value >= 100 && currentPosition?.latitude !== 0 && currentPosition?.longitude !== 0 ) {
      //setBounds([currentPosition?.latitude, currentPosition?.longitude]);
      return (
        <Circle 
          center={{lat:currentPosition?.latitude, lng: currentPosition?.longitude}}
          fillColor="blue" 
          radius={radius.imperial}
          metric={true}/>
      );
    } else {
      return (<></>);
    }
  }

  function SetViewOnClick() {
    const map = useMapEvent('click', (e) => {
      if(!popupisopen.current){
        if (idWatchGeoLoc > 0) {
          navigator.geolocation.clearWatch(idWatchGeoLoc);
          setWatchGeoLoc(-1);
        }
        setCurrentPosition({latitude: e?.latlng?.lat, longitude: e?.latlng?.lng});
        console.log("e", e);
        map.setView(e.latlng, mapZoom, {
          animate: true,
        });
      }
    });
    return null;
  }

  function SetViewOnZoomChange() {
    const map = useMapEvents({
      zoomend: () => {
          //console.log("zoom change", map.getZoom());
          setMapZoom(map.getZoom());
      }
    });
    return null;
  }

  function Ask(props){
    
    const {
        isShown,
        title,
        textAsk,
        btnTextYes,
        onClickBtnYes,
        btnTextNo,
        onClickBtnNo
        } = props;
    
        return (
          <Modal show={isShown} onHide={onClickBtnNo} backdrop="static" keyboard={false} >
              <Modal.Header closeButton>
                  <Modal.Title>{title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{textAsk}</Modal.Body>
              <Modal.Footer>
                  <Button variant="danger" onClick={onClickBtnYes}>{btnTextYes}</Button>
                  <Button variant="primary" onClick={onClickBtnNo}>{btnTextNo}</Button>
              </Modal.Footer>
          </Modal>  
      );
    }

  return (
    <div ref={refMap}>
      <Menu />
      <Spinner isVisible={spinnerVisible} />
      <LeafMap
        key={JSON.stringify([mapZoom, currentPosition?.latitude, currentPosition?.longitude])}
        center={[currentPosition?.latitude, currentPosition?.longitude]}
        zoom={mapZoom}
        style={{ height: "95vh" }}
        attributionControl={false}
        closePopupOnClick={false}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <RenderCurrentPosition />
        <RenderClientsPosition />
        <RenderDrawCircle />
        <SetViewOnClick />
        <SetViewOnZoomChange />
      </LeafMap>
      <FilterMap
        show={showFilter}
        filter={filter}
        onClose={()=>{setShowFilter(false)}}
        radius={radius}
        onDoFilter={(filter, radius) => {
          setFindInputValue("");
          setFindInputIsVisible(!findInputIsVisible);
          setFilter(filter);
          setRadius(radius);
          //setMapZoom(isMobile ? value?.zoomMobile : value?.zoomNoMobile);
          setShowFilter(false);
        }}
      />
      <InputFindAddress 
        show={!showFilter}
        label="Aller à l'adresse indiquée"
        value={findInputValue}
        onChange={(e)=>{
          setFindInputValue(e.target.value);
        }}
        onSelect={(place) => {
          var obj = {id:moment().valueOf(), address:place.formatted_address, lat:place?.geometry?.location?.lat(), lng:place?.geometry?.location?.lng()}
          dispatch(addHistoric(obj))
          setFindInputValue("");
          if (idWatchGeoLoc > 0) {
            navigator.geolocation.clearWatch(idWatchGeoLoc);
            setWatchGeoLoc(-1);
          }
          setCurrentPosition({
            latitude: place?.geometry?.location?.lat(),
            longitude: place?.geometry?.location?.lng()
          });
        }}
        eraseOnClick={(ref)=>{
          setFindInputValue("");
          ref?.current?.focus();
          watchPosition();
        }}
        bpOnClick={()=>{
          setFindInputValue("");
          popupisopen.current = false;
          setFindInputIsVisible(!findInputIsVisible);
          setHistoricAddressIsVisible(false);
        }}
        visible={findInputIsVisible}
      />
      <HistoryAddress 
        show={iconhistoricAddressIsVisible}
        label="Historique de navigation"
        value={historic}
        bpOnClick={()=>{
          popupisopen.current = false;
          setHistoricAddressIsVisible(!historicAddressIsVisible);
          setFindInputIsVisible(false);
        }}
        bpResetOnClick={()=>{
          dispatch(resetHistoric())
          setHistoricAddressIsVisible(false);
        }}
        bpCloseOnClick={()=>{
          setHistoricAddressIsVisible(false);
        }}
        onLineClick={(lat, lng, address)=>{
          var obj = {id:moment().valueOf(), address:address, lat:lat, lng:lng}
          dispatch(addHistoric(obj))
          setHistoricAddressIsVisible(false);
          if (idWatchGeoLoc > 0) {
            navigator.geolocation.clearWatch(idWatchGeoLoc);
            setWatchGeoLoc(-1);
          }
          setCurrentPosition({
            latitude: lat,
            longitude: lng
          });
        }}
        bpDeleteOnClick={(id)=>{
          dispatch(deleteHistoric(id))
        }}
        listvisible={historicAddressIsVisible}
      />
      <RoundIconGeo
        show={idWatchGeoLoc < 0 && !showFilter}
        click={()=>{
          setFindInputValue("");
          setFindInputIsVisible(false);
          watchPosition();
          popupisopen.current = false;
        }}
      />
      <RoundIconSearch
        show={!showFilter}
        click={()=>{
          setFindInputValue("");
          setFindInputIsVisible(false);
          setShowFilter(true);
          popupisopen.current = false;
        }}
      />
      
      <AlertError message={status} show={status?.length > 0}/>
    </div>
  );
}

export default Map;