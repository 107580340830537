import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from "react-hook-form";

import styled from "styled-components";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import ModalFullScreen from '@Components/modal/fullScreen';
import optionsRadius from '@Constants/optionsRadius';
import RSelect from '@Components/form/select';
import RCheckMarker from '@Components/form/checkMarker';
import RDateRange from '@Components/form/dateRange';
import clientStatus from '@Constants/clientStatus';
import colors from '@Constants/colors';
import _ from 'lodash';
import moment from 'moment';

import userProfiles from '@Constants/userProfiles';

import { getAgencies } from '@Store/Agency';
import { getCommercials } from '@Store/Commercial';
import { getFamilies } from '@Store/Family';

const Btns = styled.div`
    margin-top: 40px;
    width: 350px;
    display: flex;
    align-items: center;
    justify-content: space-between; 
`;

const DivChecks = styled.div`
  margin-top: 7px;
  width: 350px;
  display: flex;
  flex-wrap: wrap;
`;

const SelectR = styled(Select)`
  font-size: 16px;
  width: 350px;
  min-height: 38px !important;
  border: 1px solid ${colors.BLACK};
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
  .css-13cymwt-control {
    border-style: none;
  }
`;

const PopupText = styled.div`
    font-size: 14px;
    color: ${colors.PRIMARY};
    font-weight: bold;
    margin-bottom: -10px;
`;

const ColR = styled(Col)`
  margin-top: 2px;
  margin-bottom: 2px;
`;

const BtnOutline = styled(Button)`
  border-color: ${colors.SECONDARY};
  color: ${colors.SECONDARY};
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 0;
  font-weight: bold;
  text-transform: uppercase;
  width: 160px;
  border-radius: 5px;
  height: 38px;
  &:active {
    border-color: ${colors.TERTIARY};
    background-color: transparent;
  }
`;

const Submit = styled.input.attrs({ type: 'submit' })`
  background: ${colors.SECONDARY};
  color: ${colors.WHITE};
  cursor: pointer;
  margin-bottom: 0;
  font-weight: bold;
  text-transform: uppercase;
  width: 160px;
  border-radius: 5px;
  height: 38px;
  border-color: transparent;
  box-shadow: 0px;
  outline: none;
  transition: 0.15s;
  text-align: center;
  text-transform: uppercase;
  &:active {
    background-color: ${colors.TERTIARY};
  }
`;

function FilterMap({show, onClose, radius, filter, onDoFilter}) {
  const dispatch = useDispatch();
  const [setError] = useState(false);
  const user = useSelector(state => state?.user);
  const agencies = useSelector(state => state?.agency.agencies);
  const products = useSelector(state => state?.family.families);

  const selectRadius = useRef(null);
  const selectAgency = useRef(null);
  const selectProduct = useRef(null);
  const inputDateRangeStart = useRef(null);
  const inputDateRangeEnd = useRef(null);

  const {...methods} = useForm({mode: 'onChange'});

  const onSubmit = (data) => {
    console.log("data", data);
    let filters = {};
    if (data?.rOneAnnule || data?.renseigneEnAttente || data?.renseignePerdu || data?.renseigneVendu || data?.annulationClient) {
      const states = [];
      if (data?.rOneAnnule) {
        states.push(2);
      }
      if (data?.renseigneEnAttente) {
        states.push(4);
      }
      if (data?.renseigneVendu) {
        states.push(5);
      }
      if (data?.renseignePerdu) {
        states.push(6);
      }
      if (data?.annulationClient) {
        states.push(7);
      }
      filters.etats = states;
    }
    if (data?.agence?.value > 0) {
      filters.idAgence = data?.agence?.value;
    }
    if (data?.produit?.value > 0) {
      filters.familleProduit = data?.produit?.value;
    }
    if (data?.dateStart && !(typeof data?.dateStart === 'string' || data?.dateStart instanceof String)) {
      filters.dateDebut = moment(data?.dateStart).format('YYYYMMDD');
    }
    if (data?.dateEnd && !(typeof data?.dateEnd === 'string' || data?.dateEnd instanceof String)) {
      filters.dateFin = moment(data?.dateEnd).format('YYYYMMDD');
    }
    console.log("filters", filters, _.map(filters).length);
    if (_.map(filters).length === 0) {
      filters = null;
    }
    if (onDoFilter) {
      onDoFilter(filters, data?.radius);
    }
  }

  const onError = (errors, e) => {
      return console.log({errors});
  }

  useEffect(() => {
    const allElements = true;
    dispatch(getAgencies(allElements));
    dispatch(getFamilies(allElements));
console.log("FILTER ----------------->", filter);
    methods.setValue("rOneAnnule", filter?.etats?.includes(2));
    methods.setValue("renseigneEnAttente", filter?.etats?.includes(4));
    methods.setValue("renseigneVendu", filter?.etats?.includes(5));
    methods.setValue("renseignePerdu", filter?.etats?.includes(6));
    methods.setValue("annulationClient", filter?.etats?.includes(7));

  }, []);

  useEffect(() => {
    //console.log("Date de début change !!!");

    if (methods.getValues()?.dateEnd < methods.getValues()?.dateStart) {
      methods.setValue("dateEnd", moment(methods.getValues()?.dateStart).endOf('month').toDate());
    }
  }, [methods.getValues()]);

  methods.watch({ name: "dateStart" });
  methods.watch({ name: "dateEnd" });
  //console.log("value dates", methods.getValues()?.dateStart, methods.getValues()?.dateEnd);

  function FilterContent() {
    return(
      <FormProvider {...methods}>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicId">
            <ColR sm="3">
                <PopupText>Rayon de recherche</PopupText>
                <RSelect
                  refs={selectRadius}
                  id="radius"
                  name="radius"
                  defaultValue={radius}
                  data={optionsRadius}/>
            </ColR>
            <ColR sm="12">
                <PopupText>Types de marker à afficher</PopupText>
                <DivChecks>
                  {(clientStatus.map((oneClientStatus, index) =>
                    <>
                      {oneClientStatus.inFilter && <RCheckMarker
                        key={index}
                        id={oneClientStatus.id}
                        name={oneClientStatus.name}
                        label={oneClientStatus.label}
                        color={oneClientStatus.color}
                        textColor={oneClientStatus.textColor}
                        iconMarker={oneClientStatus.iconMarker}
                        checkColor={oneClientStatus.checkColor}
                      />}
                    </>
                  ))}
                </DivChecks>
            </ColR>
            <ColR sm="3">
                <PopupText>Agence</PopupText>
                <RSelect
                  refs={selectAgency}
                  id="agency"
                  name="agence"
                  //isDisabled={user?.idProfil === userProfiles.COMMERCIAL || user?.idProfil === userProfiles.RESPONSABLE_AGENCE}
                  /*defaultValue={(user?.idProfil === userProfiles.COMMERCIAL || user?.idProfil === userProfiles.RESPONSABLE_AGENCE)?
                    {value: user?.idAgence, label: user?.nomAgence}:
                    {value: 0, label: 'Toutes les agences'}}*/
                  defaultValue={{value: 0, label: 'Toutes les agences'}}
                  data={agencies}
                  setFormError={setError}/>
                    
            </ColR>
            <ColR sm="3">
                <PopupText>Produits</PopupText>
                <RSelect
                  refs={selectProduct}
                  id="product"
                  name="produit"
                  defaultValue={{value: 0, label: 'Tous les produits'}}
                  data={products}
                  setFormError={setError}/>
            </ColR>
            <ColR sm="3">
              <RDateRange
                refs={inputDateRangeStart}
                type="dateStart"
                id="dateStart"
                name="dateStart"
                label="Date de début"
                choiceMonthOnly={true}
                defaultValue=""
                onErase={()=>{
                  methods.setValue("dateStart", null);
                }}
                setFormError={setError}/>
            </ColR>
            <ColR sm="3">
              <RDateRange
                refs={inputDateRangeEnd}
                type="dateEnd"
                id="dateEnd"
                name="dateEnd"
                label="Date de fin"
                choiceMonthOnly={true}
                endPeriod={true}
                minDate={methods.getValues()?.dateStart || null}
                defaultValue=""
                onErase={()=>{
                  methods.setValue("dateEnd", null);
                }}
                setFormError={setError}/>
            </ColR>
          </Form.Group>
          <Btns>
              <BtnOutline
                  onClick={onClose}>
                  Annuler
              </BtnOutline>
              <Submit value="Filtrer" onClick={methods.handleSubmit(onSubmit, onError)}/>
          </Btns>
        </Form>
      </FormProvider>
    );
  }

  return (
    <>
        <ModalFullScreen 
            show={show}
            title="Filtrage sur la cartographie"
            content={<FilterContent />}
            onClose={onClose}
            showFooter={false}
        />
    </>
  );
}

export default FilterMap;