import {v4 as uuidv4} from 'uuid';
export const DEVICE_GET_LOADING = 'device/DEVICE_GET_LOADING';
export const DEVICE_GET_SUCCESS = 'device/DEVICE_GET_SUCCESS';
export const DEVICE_GET_ERROR = 'device/DEVICE_GET_ERROR';


const initialState = {
    deviceUUID: '',
    isGetInProgress: false,
    isGetComplete: false,
    
    error: ''
};

export function reduxThunkReducer(state = initialState, action) {
    switch (action.type) {
        
        case DEVICE_GET_LOADING: {
            return {
                ...state,
                isGetInProgress: true,
                isGetComplete: false,
                error: '',
            };
        }
        case DEVICE_GET_SUCCESS: {
            return {
                ...state,
                isGetInProgress: false,
                isGetComplete: true,
                deviceUUID: action?.deviceUUID || '',
            };
        }
        case DEVICE_GET_ERROR: {
            return {
                ...state,
                isGetInProgress: false,
                isGetComplete: false,
                error: action?.error || '',
            };
        }
        default: {
            return {...state};
        }
    }
}

////////////////////////////////////////////////////////////////////
export const checkDeviceUUID = () => {
  return (dispatch, getState) => {
    const uuid = uuidv4();
    const state = getState();
    if (!state?.device?.deviceUUID || state?.device?.deviceUUID?.length === 0) {
      dispatch({ 
        type: DEVICE_GET_SUCCESS,
        deviceUUID: uuid
      }); 
    }
  };
};

