import { BallTriangle } from  'react-loader-spinner';
import styled from "styled-components";
import colors from '@Constants/colors';

const Spin = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left:-100px;
  z-index: 10000;
  margin-top:-35px;
`;

function Spinner({isVisible}) {

  return (
    <>
      <Spin>
        <BallTriangle
            height={200}
            width={200}
            radius={5}
            color={colors.WHITE}
            ariaLabel="ball-triangle-loading"
            visible={isVisible}
        />
        </Spin>
    </>
  );
}

export default Spinner;