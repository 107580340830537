import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getAgencies } from '@Store/Agency';
import { getCommercials } from '@Store/Commercial';
import { getType } from '@Store/Activity';
import { addActivity } from '@Store/Activity';
import { getActivity } from '@Store/Activity';
import Menu from '@Menu';
import RSelect from '@Components/form/select';
import RInput from '@Components/form/input';
import RDate from '@Components/form/date';
import styled from "styled-components";
import Form from 'react-bootstrap/Form';
import colors from '@Constants/colors';
import userProfiles from '@Constants/userProfiles';

import types from '@Constants/types';

const Title = styled.h1`
    color: ${colors.BLACK};
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 10px 0px 10px 0px;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Hline = styled.div`
    width: 100%;
    height: 1px;
    background: ${colors.BLACK};
`;

const Submit = styled.input.attrs({ type: 'submit' })`
  background: ${colors.SECONDARY};
  color: ${colors.WHITE};
  cursor: pointer;
  margin-bottom: 0;
  font-weight: bold;
  text-transform: uppercase;
  width: 350px;
  border-radius: 5px;
  height: 38px;
  border-color: transparent;
  box-shadow: 0px;
  outline: none;
  transition: 0.15s;
  text-align: center;
  text-transform: uppercase;
  &:active {
    background-color: ${colors.TERTIARY};
  }
`;

function NewActivity(){
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [setError] = useState(false);
    const [NBLabel, setNBLabel]= useState(null);

    const selectAgency = useRef(null);
    const selectCommercial = useRef(null);
    const selectActivity = useRef(null);
    const inputDate = useRef(null);
    const inputDuration = useRef(null);
    const inputNB = useRef(null);

    const {...methods} = useForm({mode: 'onChange'})

    const user = useSelector(state => state?.user);
    const agencies = useSelector(state => state?.agency.agencies);
    const commercials = useSelector(state => state?.commercial.commercials);
    const activities = types;
    //const activities = useSelector(state => state?.activity.activities);
    const activity = useSelector(state => state?.activity);

    useEffect(() => {
            dispatch(getType());
            dispatch(getAgencies());
            dispatch(getCommercials());

            const now = new Date();
            now.setMinutes(Math.ceil(now.getMinutes() / 15) * 15);
            methods.setValue('dateActivité', now);
    }, []);

    useEffect(() => {
        var currentType = methods.getValues("type");
        var listActivity = [activities[2].label,activities[3].label,activities[4].label,activities[5].label];
        var pos = listActivity.indexOf(currentType.label);
        if(pos === -1){
            setNBLabel(null);
        }else{
            setNBLabel(currentType.label);
        }
    }, [methods.watch("type")]);

    const onSubmit = (data) => {
        dispatch(getType());
        console.log("onSubmit", data);
        //dispatch(addActivity());
    }

    const onError = (errors, e) => {
        return console.log({errors});
    }

    function NBAdd(){
        if(NBLabel !== null){
            switch (NBLabel) {
                case activities[2].label: {
                    return( 
                        <>
                        <RInput
                            refs={inputNB}
                            type="number"
                            id="NB"
                            name="NB"
                            label="NB argument TEL"
                            defaultValue="0"
                            rules={{
                                required: 'Le NB est obligatoire',
                                min: {
                                    value: 0,
                                    message: "Le NB ne peut pas être inférieur à 0"
                                }
                            }}
                            setFormError={setError}/>
                        </>
                    );
                }
                case activities[3].label: {
                    return( 
                        <>
                        <RInput
                            refs={inputNB}
                            type="number"
                            id="NB"
                            name="NB"
                            label="NB argument BAL"
                            defaultValue="0"
                            rules={{
                                required: 'Le NB est obligatoire',
                                min: {
                                    value: 0,
                                    message: "Le NB ne peut pas être inférieur à 0"
                                }
                            }}
                            setFormError={setError}/>
                        <RInput
                            refs={inputNB}
                            type="number"
                            id="NB2"
                            name="NB2"
                            label="NB argument PAP"
                            defaultValue="0"
                            rules={{
                                required: 'Le NB est obligatoire',
                                min: {
                                    value: 0,
                                    message: "Le NB ne peut pas être inférieur à 0"
                                }
                            }}
                            setFormError={setError}/>
                    </>
                    );
                }
                default: {
                    return( 
                        <>
                        <RInput
                            refs={inputNB}
                            type="number"
                            id="NB"
                            name="NB"
                            label={`NB argument ${NBLabel}`}
                            defaultValue="0"
                            rules={{
                                required: 'Le NB est obligatoire',
                                min: {
                                    value: 0,
                                    message: "Le NB ne peut pas être inférieur à 0"
                                }
                            }}
                            setFormError={setError}/>
                        </>
                    );
                }
            }   
        }else return(null)
    }

    return (
        <>
        <Menu />
        <Wrapper>
            <FormProvider {...methods}>
                <Form>
                    <Title>Ajouter une activité</Title>
                    <Form.Group className="mb-3" controlId="formBasicId">
                        <RSelect
                            refs={selectAgency}
                            id="agency"
                            name="agence"
                            label="Agence"
                            isDisabled={user?.idProfil === userProfiles.COMMERCIAL || user?.idProfil === userProfiles.RESPONSABLE_AGENCE}
                            defaultValue={{value: user?.idAgence, label: user?.nomAgence}}
                            data={agencies}
                            rules={{
                                required: 'Le nom de l\'agence est obligatoire',
                            }}
                            setFormError={setError}/>
                        <RSelect
                            refs={selectCommercial}
                            id="commercial"
                            name="commercial"
                            label="Commercial(e)"
                            isDisabled={user?.idProfil === 8}
                            defaultValue={{value: user?.idCommercial, label: user?.lastname + ' ' + user?.firstname}}
                            data={commercials}
                            rules={{
                                required: 'Le nom de commercial est obligatoire'
                            }}
                            setFormError={setError}/>
                        <Hline />
                        <RSelect
                            refs={selectActivity}
                            id="type"
                            name="type"
                            label="Activité"
                            defaultValue={activities[0]}
                            data={activities}
                            rules={{
                                required: "Le type d'activité est obligatoire"
                            }}
                            setFormError={setError}/>
                        <RDate
                            refs={inputDate}
                            type="date"
                            id="date"
                            name="dateActivité"
                            label="Date"
                            defaultValue=""
                            rules={{
                                required: "La date de début d'activité est obligatoire"
                            }}
                            setFormError={setError}/>
                        <RInput
                            refs={inputDuration}
                            type="number"
                            id="durationActivity"
                            name="dureeActivité"
                            label="Durée (minutes)"
                            defaultValue="10"
                            rules={{
                                required: 'La durée est obligatoire',
                                min: {
                                    value: 1,
                                    message: "La durée ne peut pas être inférieur à 1 min"
                                }
                            }}
                            setFormError={setError}/>
                        <NBAdd/>
                    </Form.Group>
                    <Submit value="Enregistrer" onClick={methods.handleSubmit(onSubmit, onError)}/>
                </Form>
            </FormProvider>
        </Wrapper>
        </>
    );
}
export default NewActivity;