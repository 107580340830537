import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import Button from 'react-bootstrap/Button';
import styled from "styled-components";
import colors from '@Constants/colors';
import Table from 'react-bootstrap/Table';

const Wrapper = styled.div`
  display: ${props => props.$visibility? 'flex' : 'none'};
  align-items: flex-start;
  position: absolute;
  top: 80px;
  right: 15px;
  z-index:20000;
`

const Icon = styled(Button)`
  background-color: ${colors.WHITE};
  border-color: ${colors.TERTIARY};
  border-radius: 50%;
  z-index:20000;
  width: 55px;
  padding: 15px 0;

  &:hover{
    border-color: ${colors.TERTIARY};
    background-color: ${colors.TERTIARY};
  }

`;

const HistoryIcon = styled(FontAwesomeIcon)`
  color: ${colors.TERTIARY};

  &:hover{
    color: ${colors.WHITE};
  }
`;

const HistoryPopup = styled.div`
    display: ${props => props.$visibility? 'flex' : 'none'};
    background-color: ${colors.WHITE};
    border-color: ${colors.TERTIARY};
    transition: 0.2s ease all;
    flex-direction: column;
    border-width: 1px;
    border-radius: 5px;
    border-style:solid;
    margin-right: -5px;
    margin-top: 10px;
    width: 260px;
    z-index:20001;
`;

const HistoryBtns = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
`;

const Label = styled.label`
  color: ${colors.PRIMARY};
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 15px;
  z-index:20002;
  user-select: none;

`;

const HistoryTable = styled(Table)`
  margin-top: 30px;
`;

const HistoryBtnReset = styled(Button)`
  font-size: 12px;
  align-self: flex-end;
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
  border-radius: 5px;
  user-select: none;
`;

const HistoryBtnClose = styled(Button)`
  font-size: 12px;
  align-self: flex-start;
  //margin-bottom: 0.25rem;
  //margin-left: 0.25rem;
  border-radius: 5px;
  user-select: none;
`;

const HistoryIconReset = styled(FontAwesomeIcon)`
  color:#dc3545;
  transition: 0.2s ease-in-out;
  &:hover{
    color: #fff;
  }
`;

const HistoryLineIcon = styled(FontAwesomeIcon)`
  color:${colors.GREY};
`;

const HistoryDeleteLineIcon = styled(FontAwesomeIcon)`
  color:#dc3545;
`;

const HistoryLineAddress = styled.tr`
  font-size: 12px;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
`

function HistoryAddress(props){
    
    const {
        show,
        label,
        value,
        listvisible,
        bpOnClick,
        bpResetOnClick,
        bpDeleteOnClick,
        bpCloseOnClick,
        onLineClick,
        } = props;
  
      const [visible, setVisible] = useState( value.length !== 0 ? true : false );

      useEffect(() => {
        setVisible( value.length !== 0 ? true : false )
      }, [value]);

      const ChangeOnHover =()=>{
        document.getElementById('HistoryAddress').style.color = colors.WHITE;
      }
      const ChangeOnOut =()=>{
        document.getElementById('HistoryAddress').style.color = colors.TERTIARY;
      }

      const ChangeOnHoverHistoryIconReset =()=>{
        document.getElementById('HistoryIconReset').style.color = '#fff';
      }
      const ChangeOnOutHistoryIconReset =()=>{
        document.getElementById('HistoryIconReset').style.color = '#dc3545';
      }
    
      return (
        <>
          {show &&
          <Wrapper $visibility={visible}>
            <HistoryPopup $visibility={listvisible}>
              <Label>{label}</Label>
              <HistoryTable borderless striped hover size="sm">
                <tbody>
                  {value.map((histo)=>(
                    <>
                      <HistoryLineAddress>
                        <td onClick={()=>{onLineClick(histo.lat, histo.lng, histo.address)}}><HistoryLineIcon icon={faClock} size="lg"/></td>
                        <td onClick={()=>{onLineClick(histo.lat, histo.lng, histo.address)}}>{histo.address}</td>
                        <td><HistoryDeleteLineIcon onClick={()=>{bpDeleteOnClick(histo.id)}} icon={faTrashCan} size="lg"/></td>
                      </HistoryLineAddress>
                    </>
                  ))}
                </tbody>
              </HistoryTable>
              <HistoryBtns>
                <HistoryBtnReset 
                  onMouseOver={ChangeOnHoverHistoryIconReset}
                  onMouseOut={ChangeOnOutHistoryIconReset} 
                  variant="outline-danger" 
                  onClick={bpResetOnClick} 
                >
                  <HistoryIconReset id="HistoryIconReset" icon={faTrashCan} size="lg"/> Tout effacer
                </HistoryBtnReset>
                <HistoryBtnClose 
                  onMouseOver={ChangeOnHoverHistoryIconReset}
                  onMouseOut={ChangeOnOutHistoryIconReset} 
                  variant="outline-danger" 
                  onClick={bpCloseOnClick} 
                >Fermer
                </HistoryBtnClose>
              </HistoryBtns>
            </HistoryPopup>
            <Icon 
              onMouseOver={ChangeOnHover} 
              onMouseOut={ChangeOnOut} 
              onClick={bpOnClick}>
              <HistoryIcon id="HistoryAddress" icon={faClockRotateLeft} size="lg"/>
            </Icon>
          </Wrapper>}
        </>
      );
    }

export default HistoryAddress;