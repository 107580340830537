import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import Menu from '@Menu';
import RInput from '@Components/form/input';
import RTextarea from '@Components/form/textarea';
import RDate from '@Components/form/date';
import RCheck from '@Components/form/check';
import Button from 'react-bootstrap/Button';
import styled from "styled-components";
import { currentNewMeetingUpdate, saveNewMeeting, meetingReset } from '@Store/Meeting';
import Spinner from 'react-bootstrap/Spinner';
import colors from '@Constants/colors';

import Form from 'react-bootstrap/Form';

const Title = styled.h1`
    color: ${colors.BLACK};
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 10px 0px 10px 0px;
`;

const SubTitle = styled.h3`
    color: grey;
    font-size: 14px;
    font-weight: bold;
    margin: 0px 10px 30px 10px;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Btns = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between; 
`;

const BtnOutline = styled(Button)`
  border-color: ${colors.SECONDARY};
  color: ${colors.SECONDARY};
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 0;
  font-weight: bold;
  text-transform: uppercase;
  width: 174px;
  border-radius: 5px;
  height: 38px;
  &:active {
    border-color: ${colors.TERTIARY};
    background-color: transparent;
  }
`;

const Submit = styled.input.attrs({ type: 'submit' })`
  background: ${colors.SECONDARY};
  color: ${colors.WHITE};
  cursor: pointer;
  margin-bottom: 0;
  font-weight: bold;
  text-transform: uppercase;
  width: 174px;
  border-radius: 5px;
  height: 38px;
  border-color: transparent;
  box-shadow: 0px;
  outline: none;
  transition: 0.15s;
  text-align: center;
  text-transform: uppercase;
  &:active {
    background-color: ${colors.TERTIARY};
  }
`;

function NewMeetingStepThree() {
    const dispatch = useDispatch();
    const [setError] = useState(false);
    const navigate = useNavigate();

    const checkCouplePresent = useRef(null);
    const checkWithoutMeeting = useRef(null);
    const inputDateMeeting = useRef(null);
    const inputDurationMeeting = useRef(null);
    const textareaNote = useRef(null);
    
    const {...methods} = useForm({mode: 'onChange'});

    const meeting = useSelector(state => state?.meeting);
    //console.log("MEETING =====>    ", meeting);

    useEffect(() => {
        methods.resetField("couplePresent");
        methods.resetField("sansRendezVous");
        methods.resetField("dateRendezVous");
        methods.resetField("dureeRendezVous");
        methods.resetField("note");

        const now = new Date();
        now.setMinutes(Math.ceil(now.getMinutes() / 15) * 15);
        methods.setValue('dateRendezVous', now);

        methods.setValue('dureeRendezVous', 90);

        if (meeting?.current?.note?.length > 0) {
            methods.setValue('note', meeting?.current?.note);
        }

        //textareaNote.current.focus();
    }, []);

    useEffect(() => {
        if (!meeting?.updateLoading && meeting?.updateComplete) {
            dispatch(saveNewMeeting());
        }
        if (!meeting?.isSaveInProgress && meeting?.isSaveComplete) {
            dispatch(meetingReset());
            toast.success('Le nouveau contact a été ajouté avec succès 👍🏼', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            navigate("/newMeeting-step01");
        }
    }, [meeting]);

    const onSubmit = (data) => {
        //console.log("onSubmit", data);
        dispatch(currentNewMeetingUpdate(data));
    }

    const onError = (errors, e) => {
        return console.log({errors});
    }

    methods.watch({ name: 'sansRendezVous' });

    return (
        <>
        <Menu />
        <Wrapper>
            <FormProvider {...methods}>
                <Form>
                    <Title>Ajouter un contact</Title>
                    <SubTitle>Étape 3/3</SubTitle>
                    <Form.Group className="mb-3" controlId="formBasicId">
                        <RCheck
                            refs={checkCouplePresent}
                            type="checkbox"
                            id="couplePresent"
                            name="couplePresent"
                            label="Couple présent"
                            defaultValue=""
                            setFormError={setError}/>
                        <RCheck
                            refs={checkWithoutMeeting}
                            type="checkbox"
                            id="withoutMeeting"
                            name="sansRendezVous"
                            label="Sans rendez-vous"
                            defaultValue=""
                            setFormError={setError}/>
                        {!methods.getValues()?.sansRendezVous &&
                            <>
                                <RDate
                                    refs={inputDateMeeting}
                                    type="date"
                                    id="meetingDate"
                                    name="dateRendezVous"
                                    label="Rendez-vous n°1"
                                    defaultValue=""
                                    rules={{
                                        required: 'Le rendez-vous 1 est obligatoire'
                                    }}
                                    setFormError={setError}/>
                                <RInput
                                    refs={inputDurationMeeting}
                                    type="number"
                                    id="durationMeeting"
                                    name="dureeRendezVous"
                                    label="Durée (minutes)"
                                    defaultValue=""
                                    rules={{
                                        required: 'La durée est obligatoire',
                                        min: {
                                            value: 30,
                                            message: "La durée doit être comprise entre 30 et 120 minutes"
                                        },
                                        mmax: {
                                            value: 120,
                                            message: "La durée doit être comprise entre 30 et 120 minutes"
                                        }
                                    }}
                                    setFormError={setError}/>
                            </>}
                        <RTextarea
                            refs={textareaNote}
                            id="note"
                            name="note"
                            label="Note"
                            rows={4}
                            defaultValue=""
                            setFormError={setError}/>
                    </Form.Group>
                    <Btns>
                        <BtnOutline
                            onClick={()=>{navigate("/newMeeting-step02", {state: {back: true}})}}>
                            Précédent
                        </BtnOutline>
                        {(meeting?.updateLoading || meeting?.isSaveInProgress) &&
                            <Spinner animation="border" size="sm" />}
                        {!(meeting?.updateLoading || meeting?.isSaveInProgress) &&
                            <Submit value="Enregistrer"
                                onClick={methods.handleSubmit(onSubmit, onError)} />}
                    </Btns>
                </Form>
            </FormProvider>
        </Wrapper>
        </>
    );
}

export default NewMeetingStepThree;