import { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from "styled-components";
import colors from '@Constants/colors';
import { faMagnifyingGlassLocation } from '@fortawesome/free-solid-svg-icons';
import { faEraser } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Autocomplete from "react-google-autocomplete";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index:21000;
`

const Icon = styled(Button)`
  background-color: ${colors.WHITE};
  border-color: ${colors.TERTIARY};
  border-radius: 50%;
  z-index:20000;
  width: 55px;
  padding: 15px 0;

  &:hover{
    border-color: ${colors.TERTIARY};
    background-color: ${colors.TERTIARY};
  }
`;

const SearchIcon = styled(FontAwesomeIcon)`
  color: ${colors.TERTIARY};

  &:hover{
    color: ${colors.WHITE};
  }
`;

const EraseIcon = styled(FontAwesomeIcon)`
  color: ${colors.GREY};
  display: ${props => props.$visibility? 'block' : 'none'};
  z-index:20002;
  user-select: none;
  padding: 7px 4px;
  background-color: ${colors.WHITE};
`;

const FindInput = styled(Autocomplete)`
  display: ${props => props.$visibility? 'block' : 'none'};
  background-color: ${colors.WHITE};
  border-color: ${colors.TERTIARY};
  border-width: 1px;
  border-radius: 5px;
  border-style:solid;
  margin-right: -36px;
  min-width: 250px;
  padding: 5px;
  z-index:20001;

  &:focus{
    outline: none;
    border-width: 2px;
    border-radius: 5px;
    border-style:solid;
  }

`;

const Label = styled.label`
  position: absolute;
  pointer-events: none;
  left: 10px;
  display: ${props => props.$visibility? 'block' : 'none'};
  z-index:20002;
  user-select: none;

  ${props => props.$value ? `top: -10px; font-size: 14px; color: ${colors.PRIMARY}; font-weight: bold;`:`color: ${colors.GREY}; font-size: 16px; font-weight: normal; top: 15px;`}

  input:focus ~ &{
    transition: 0.2s ease all;
    top: -10px;
    font-size: 14px;
    color: ${colors.PRIMARY};
    font-weight: bold;
  }
`;


function InputFindAddress(props){
    
    const {
        show,
        name,
        label,
        value,
        visible,
        onChange,
        onSelect,
        bpOnClick,
        eraseOnClick,
        } = props;

    const findInputRef = useRef(null)
    
    useEffect(() => {
      if(visible){
        findInputRef.current.focus();
      }
    }, [visible]);
  
      const ChangeOnHover =()=>{
        document.getElementById('FindAddress').style.color = colors.WHITE;
      }
      const ChangeOnOut =()=>{
        document.getElementById('FindAddress').style.color = colors.TERTIARY;
      }
    
      return (
        <>
          {show &&
          <Wrapper>
            <FindInput
              $visibility={visible}
              ref={findInputRef}
              name={name}
              apiKey={process.env.REACT_APP_GOOGLE_ACCOUNT}
              placeholder=""
              value={value}
              onChange={onChange}
              options={{
                types: ['address'],
              }}   
              onPlaceSelected={onSelect}
            />
            <Label htmlFor={name} $value={value} $visibility={visible} >{label}</Label>
            <EraseIcon 
              icon={faEraser} 
              size="lg"
              $visibility={visible}
              onClick={() => {
                eraseOnClick(findInputRef);
            }}
            />
            <Icon 
              onMouseOver={ChangeOnHover} 
              onMouseOut={ChangeOnOut} 
              onClick={bpOnClick}>
              <SearchIcon id="FindAddress" icon={faMagnifyingGlassLocation} size="xl"/>
            </Icon>
          </Wrapper>}
        </>
      );
    }


export default InputFindAddress;