
const types =[
    {value:0, label:'VISITE DE POSE'},
    {value:1, label:'PRÉSENCE SANS ACTIVITÉ'},
    {value:2, label:'TÉLÉPROSPECTION'},
    {value:3, label:'PROSPECTION'},
    {value:4, label:'STAND'},
    {value:5, label:'FOIRE'},
    {value:5, label:'ACCOMPAGNEMENT RDV'},
    {value:5, label:'FORMATION'},
    {value:5, label:'ACCOMPAGNEMENT PROSPECTION'},
];

export default types;