import axios from '@Services/axios';

export const APIINFO_GET_LOADING = 'apiInfo/APIINFO_GET_LOADING';
export const APIINFO_GET_SUCCESS = 'apiInfo/APIINFO_GET_SUCCESS';
export const APIINFO_GET_ERROR = 'apiInfo/APIINFO_GET_ERROR';


const initialState = {
    data: {},
    comment: '',
    
    isGetInProgress: false,
    isGetComplete: false,
    
    error: ''
};

export function reduxThunkReducer(state = initialState, action) {
    switch (action.type) {
        
        case APIINFO_GET_LOADING: {
            return {
                ...state,
                isGetInProgress: true,
                isGetComplete: false,
                data: {},
                comment: '',
                error: '',
            };
        }
        case APIINFO_GET_SUCCESS: {
            return {
                ...state,
                isGetInProgress: false,
                isGetComplete: true,
                data: action?.data || {},
                comment: action?.comment || ''
            };
        }
        case APIINFO_GET_ERROR: {
            return {
                ...state,
                isGetInProgress: false,
                isGetComplete: false,
                error: action?.error || '',
            };
        }
        default: {
            return {...state};
        }
    }
}

////////////////////////////////////////////////////////////////////
const URL_API = process.env.REACT_APP_API_ENDPOINT;

export const getApiInfo = () => {
  return (dispatch) => {
      dispatch({ 
        type: APIINFO_GET_LOADING
      });
      axios.get(`${URL_API}/version`).then(
      response => {
        console.log("response INFO API", response?.data);

        dispatch({ 
          type: APIINFO_GET_SUCCESS,
          data: response?.data?.données || {},
          comment: response?.data?.commentaire
        });
    },
    error => {
      dispatch({ type: APIINFO_GET_ERROR, error: error?.response?.data });
    });
  };
};

