import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';
import '@Css/index.css';
import App from './App';


// replace console.* for disable log on production
if (process.env.REACT_APP_ENV === 'prod') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
  console.info = () => {}
  console.assert = () => {}
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
