import { useController, useFormContext } from 'react-hook-form';
import styled from "styled-components";
import Select from 'react-select';
import colors from '@Constants/colors';

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
  margin-top: 10px;
`;

const Label = styled.div`
  font-size: 14px;
  color: ${colors.PRIMARY};
  font-weight: bold;
  text-align: left !important;
`;

const SpanError = styled.div`
  color: ${colors.RED};
  font-size: 14px;
  font-weight: normal;
  text-align: left;
`;

const SelectCustom = styled(Select)`
  font-size: 16px;
  width: 350px;
  min-height: 38px !important;
  border: 1px solid ${colors.BLACK};
  border-radius: 5px;
  .css-13cymwt-control {
    border-style: none;
  }
`;


function ControlledSelect(props) {
    const formContext = useFormContext();
    const { formState } = formContext;

    const {
        id,
        name,
        data,
        label,
        rules,
        defaultValue,
        placeholder,
        isDisabled,
        refs,
        } = props;

    const { field } = useController({ name, rules, defaultValue });

    const hasError = Boolean(formState?.errors[name]);
    return (
      <Wrapper>
        {label?.length > 0 &&<Label htmlFor={name}>{label}</Label>}
        <SelectCustom
            ref={refs}
            id={id}
            name={name}
            isDisabled={isDisabled}
            placeholder={placeholder}
            isSearchable={false}
            options={data}
            onChange={field.onChange}
            defaultValue={field?.value || defaultValue}
        />
        {hasError && (<SpanError>{formState.errors[name].message || ''}</SpanError>)}
      </Wrapper>
    );
}

function RSelect(props) {

  const {
    name,
    setFormError
  } = props;

  const formContext = useFormContext();

  // Placeholder until input name is initialized
  if (!formContext || !name) {
    //const msg = !formContext ? "TextInput must be wrapped by the FormProvider" : "Name must be defined";
    setFormError(true);
    return null;
  }

  return <ControlledSelect {...props} />;

};

export default RSelect;