import axios from '@Services/axios';
import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

export const CURRENT_MEETING_UPD_INIT = 'meeting/CURRENT_MEETING_UPD_INIT';
export const CURRENT_MEETING_UPD_LOADING = 'meeting/CURRENT_MEETING_UPD_LOADING';
export const CURRENT_MEETING_SUCCESS = 'meeting/CURRENT_MEETING_SUCCESS';
export const CURRENT_MEETING_ERROR = 'meeting/CURRENT_MEETING_ERROR';

export const NEWMEETING_SAVE_LOADING = 'meeting/NEWMEETING_SAVE_LOADING';
export const NEWMEETING_SAVE_SUCCESS = 'meeting/NEWMEETING_SAVE_SUCCESS';
export const NEWMEETING_SAVE_ERROR = 'meeting/NEWMEETING_SAVE_ERROR';

export const CURRENT_MEETING_RESET = 'meeting/CURRENT_MEETING_RESET';


const initialState = {
    
    /*current: {
        idClient: 0,
        idAgence: 1,
        idCommercial: 4,
        datePriseDeContact: "11/02/2023 15:30",
        idTitre: 2,
        nom: "THOMAS",
        prenom: "Jay",
        adresse: "149 Route de Saint Florent des Bois",
        codePostal: "85000",
        ville: "La Roche-sur-Yon",
        telephone: "",
        mobile: "0683263696",
        email: "jay.scott.thomas@gmail.com",
        idSource: 5,
        precisionSource: "",
        couplePresent: 0,
        sansRendezVous: 0,
        dateRendezVous: "22/02/2023 15:30",
        dureeRendezVous: 90,
        note: "Test de la note (dernière étape)."
    },*/
    current:{},
    updateLoading: false,
    updateComplete: false,
    errorUpdate: '',

    isSaveInProgress: false,
    isSaveComplete: false,
    error: ''
};

export function reduxThunkReducer(state = initialState, action) {
    switch (action.type) {
        case CURRENT_MEETING_UPD_INIT: {
            return {
                ...state,
                errorUpdate: '',
                updateLoading: false,
                updateComplete: false
            };
        }
        case CURRENT_MEETING_UPD_LOADING: {
            return {
                ...state,
                errorUpdate: '',
                updateLoading: true,
                updateComplete: false
            };
        }
        case CURRENT_MEETING_SUCCESS: {
            return {
                ...state,
                errorUpdate: '',
                updateLoading: false,
                updateComplete: true,
                current: action?.dataUpdated || {},
            };
        }
        case CURRENT_MEETING_ERROR: {
            return {
                ...state,
                errorUpdate: action?.errorUpdate || '',
                updateLoading: false,
                updateComplete: false
            };
        }
        case NEWMEETING_SAVE_LOADING: {
            return {
                ...state,
                updateLoading: false,
                updateComplete: false,
                isSaveInProgress: true,
                isSaveComplete: false,
                error: '',
            };
        }
        case NEWMEETING_SAVE_SUCCESS: {
            return {
                ...state,
                isSaveInProgress: false,
                isSaveComplete: true,
            };
        }
        case NEWMEETING_SAVE_ERROR: {
            return {
                ...state,
                isSaveInProgress: false,
                isSaveComplete: false,
                error: action?.error || '',
            };
        }
        case CURRENT_MEETING_RESET: {
            return {
                ...state,
                current: {},
                updateLoading: false,
                updateComplete: false,
                isSaveInProgress: false,
                isSaveComplete: false,
                error: '',
            };
        }
        default: {
            return {...state};
        }
    }
}

////////////////////////////////////////////////////////////////////
export const currentNewMeetingUpdate = (newData) => {
    return (dispatch, getState) => {
        dispatch({ 
            type: CURRENT_MEETING_UPD_LOADING
          });
        
      const state = getState();
      const oldData = state?.meeting?.current;
  
      if (!newData) {
        dispatch({ type: CURRENT_MEETING_ERROR, error: 'Il manque des paramètres: newData' });
      }
      else {
        const dataUpdated = Object.assign(oldData, newData);
        dispatch({ type: CURRENT_MEETING_SUCCESS, dataUpdated });
      }
    };
};

export const meetingReset = () => {
    return (dispatch) => {
        dispatch({ 
            type: CURRENT_MEETING_RESET
          });
    };
};

export const meetingUpdateStepCurrentFinished = () => {
    return (dispatch) => {
        dispatch({ 
            type: CURRENT_MEETING_UPD_INIT
          });
    };
};

////////////////////////////////////////////////////////////////////
const URL_API = process.env.REACT_APP_API_ENDPOINT;

export const saveNewMeeting = () => {
  return (dispatch, getState) => {
      dispatch({ 
        type: NEWMEETING_SAVE_LOADING
      });

      const state = getState();
      const current = state?.meeting?.current;

      const obj = {
        idClient: current?.idClient || 0,
        idAgence: current?.agence?.value || 0,
        idCommercial: current?.commercial?.value || 0,
        datePriseDeContact: moment(current.datePriseDeContact).format('L LT'),
        idTitre: current?.titre?.value || 0,
        nom: current?.nom || "",
        prenom: current?.prenom || "",
        adresse: current?.adresse || "",
        codePostal: current?.codePostal || "",
        ville: current?.ville || "",
        telephone: current?.telephone || "",
        mobile: current?.mobile || "",
        email: current?.email || "",
        idSource: current?.source?.value || 0,
        precisionSource: current?.precisionSource || "",
        couplePresent: current?.couplePresent ? 1: 0,
        sansRendezVous: current?.sansRendezVous ? 1: 0,
        dateRendezVous: moment(current.dateRendezVous).format('L LT'),
        dureeRendezVous: current?.dureeRendezVous || 0,
        note: current?.note || ""
      };

      console.log("PREPARE object to SAVE =======> ", obj);
       
      axios.post(`${URL_API}/ajouteContact`, obj).then(
      response => {
        console.log("       ====> RESPONSE New Meeting ====> ", response?.data);

        dispatch({ 
          type: NEWMEETING_SAVE_SUCCESS,
        });
    },
    error => {
      dispatch({ type: NEWMEETING_SAVE_ERROR, error: error?.response?.data });
    });
  };
};

