import React from 'react';
import Alert from 'react-bootstrap/Alert';
import styled from "styled-components";

const AlertCustom = styled(Alert)`
    margin-top: 20px;
    margin-bottom: 30px;
    max-width: 350px;
`;

function AlertError({message, show}) {
    if (show) {
        return (
        <AlertCustom variant="danger">
            <Alert.Heading>Oh oh ! problème ! 😳</Alert.Heading>
            <p>
            {message || ''}
            </p>
        </AlertCustom>
        );
    } else {
        return(<></>);
    }
}

export default AlertError;