import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseUser, faUser, faPhone, faMailBulk, faMobile, faWarning } from '@fortawesome/free-solid-svg-icons';
import Alert from 'react-bootstrap/Alert';
import Menu from '@Menu';
import RInput from '@Components/form/input';
import RSelect from '@Components/form/select';
import RDate from '@Components/form/date';
import styled from "styled-components";
import { currentNewMeetingUpdate, meetingReset, meetingUpdateStepCurrentFinished } from '@Store/Meeting';
import { getTitles } from '@Store/Title';
import { getAgencies } from '@Store/Agency';
import { getCommercials } from '@Store/Commercial';
import { getClients } from '@Store/Client';
import ModalFullScreen from '@Components/modal/fullScreen';
import userProfiles from '@Constants/userProfiles';
import Form from 'react-bootstrap/Form';
import colors from '@Constants/colors';

const Title = styled.h1`
    color: ${colors.BLACK};
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 10px 0px 10px 0px;
`;

const Hline = styled.div`
    width: 100%;
    height: 1px;
    background: ${colors.BLACK};
`;

const SubTitle = styled.h3`
    color: grey;
    font-size: 14px;
    font-weight: bold;
    margin: 0px 10px 10px 10px;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Submit = styled.input.attrs({ type: 'submit' })`
  background: ${colors.SECONDARY};
  color: ${colors.WHITE};
  cursor: pointer;
  margin-bottom: 0;
  font-weight: bold;
  text-transform: uppercase;
  width: 350px;
  border-radius: 5px;
  height: 38px;
  border-color: transparent;
  box-shadow: 0px;
  outline: none;
  transition: 0.15s;
  text-align: center;
  text-transform: uppercase;
  &:active {
    background-color: ${colors.TERTIARY};
  }
`;

const ListContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const ItemClient = styled.div`
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 8% 92%;
    border-color: ${colors.BLACK};
    border:1px solid;
    border-radius: 5px;
    padding: 5px 5px 5px 5px;
    margin-bottom: 5px;
`;

const ItemClientNameIcon = styled.div`
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
`;
const ItemClientNameLabel = styled.div`
    font-weight: bold;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
`;

const ItemClientAdressIcon = styled.div`
    color: ${colors.BLUE};
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
`;

const ItemClientAdressLabel = styled.div`
    color: ${colors.BLUE};
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
`;

const ItemClientAdress2Icon = styled.div`
    color: ${colors.BLUE};
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
`;

const ItemClientAdress2Label = styled.div`
    color: ${colors.BLUE};
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
    margin-top: -10px;
`;

const ItemClientPhoneIcon = styled.div`
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end: 5;
`;

const ItemClientPhoneLabel = styled.div`
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
`;

const ItemClientMobileIcon = styled.div`
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 5;
    grid-row-end: 6;
`;

const ItemClientMobileLabel = styled.div`
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 5;
    grid-row-end: 6;
`;

const ItemClientEmailIcon = styled.div`
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 6;
    grid-row-end: 7;
`;

const ItemClientEmailLabel = styled.div`
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 6;
    grid-row-end: 7;
`;

const ItemClientWarningIcon = styled.div`
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 7;
    grid-row-end: 8;
`;

const ItemClientWarningLabel = styled.div`
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 7;
    grid-row-end: 8;
    color: red;
`;

function NewMeetingStepOne() {
    const dispatch = useDispatch();
    const [setError] = useState(false);
    const [listClientShow, setListClientShow]= useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const isBack = location?.state?.back;

    const selectAgency = useRef(null);
    const selectCommercial = useRef(null);
    const selectTitle = useRef(null);
    const inputDate = useRef(null);
    const inputLastname = useRef(null);
    const inputFirstname = useRef(null);
    
    const {...methods} = useForm({mode: 'onChange'})

    const user = useSelector(state => state?.user);
    const meeting = useSelector(state => state?.meeting);
    const titles = useSelector(state => state?.title.titles);
    const agencies = useSelector(state => state?.agency.agencies);
    const commercials = useSelector(state => state?.commercial.commercials);
    const clients = useSelector(state => state?.client.clients);

    useEffect(() => {
        if (!isBack) {
            
            dispatch(meetingReset());
            dispatch(getTitles());
            dispatch(getAgencies());
            dispatch(getCommercials());

            methods.resetField("nom");
            methods.resetField("prenom");
            dispatch(getClients(''));

            const now = new Date();
            now.setMinutes(Math.ceil(now.getMinutes() / 15) * 15);
            methods.setValue('datePriseDeContact', now);

            //inputLastname.current.focus();
        } else {
            if (meeting?.current?.datePriseDeContact) {
                methods.setValue('datePriseDeContact', meeting?.current?.datePriseDeContact);
            }
            if (meeting?.current?.nom?.length > 0) {
                methods.setValue('nom', meeting?.current?.nom);
            }
            if (meeting?.current?.prenom?.length > 0) {
                methods.setValue('prenom', meeting?.current?.prenom);
            }
        }
    }, []);

    useEffect(() => {
        if (!meeting?.updateLoading && meeting?.updateComplete) {
            navigate("/newMeeting-step02");
            dispatch(meetingUpdateStepCurrentFinished());
        }
    }, [meeting]);

    const onSubmit = (data) => {
        //console.log("onSubmit", data);
        dispatch(currentNewMeetingUpdate(data));
    }

    const onError = (errors, e) => {
        return console.log({errors});
    }

    function ClientOneItem({data}) {
        return (
            <ItemClient key={data.idClient} onClick={(e) => {
                //console.log("onClick", data);

                if ((user.idProfil === userProfiles.COMMERCIAL && user.idCommercial !== data?.idCommercial)
                || (user.idProfil === userProfiles.RESPONSABLE_AGENCE && user.idAgence !== data?.idAgence)) {
                    return;
                }

                methods.setValue('nom', data?.nom || '');
                dispatch(getClients(data?.nom));
                methods.setValue('prenom', data?.prenom || '');
                methods.setValue('idClient', data?.idClient || 0);
                
                methods.setValue('adresse', data?.adresse || '');
                methods.setValue('codePostal', data?.codePostal || 0);
                methods.setValue('ville', data?.ville || '');
                methods.setValue('latitude', data?.latitude || 0);
                methods.setValue('longitude', data?.longitude || 0);
                if (data?.telephone !== "00.00.00.00.00" && data?.telephone?.length > 0) {
                    methods.setValue('telephone', data?.telephone || '');
                }
                if (data?.mobile !== "00.00.00.00.00" && data?.mobile?.length > 0) {
                    methods.setValue('mobile', data?.mobile || '');
                }
                if (data?.email?.length > 5 ) {
                    methods.setValue('email', data?.email || '');
                }
                methods.setValue('note', data?.note || '');
                setListClientShow(false);
            }}>
                <ItemClientNameIcon><FontAwesomeIcon icon={faUser} size="1x"/></ItemClientNameIcon>
                <ItemClientNameLabel>{data?.prenom || ''} {data?.nom || ''}</ItemClientNameLabel>
                <ItemClientAdressIcon><FontAwesomeIcon icon={faHouseUser} size="1x"/></ItemClientAdressIcon>
                <ItemClientAdressLabel>{data?.adresse || ''}</ItemClientAdressLabel>
                <ItemClientAdress2Icon />
                <ItemClientAdress2Label>{data?.codePostal || ''} {data?.ville || ''}</ItemClientAdress2Label>
                {data?.telephone !== "00.00.00.00.00" && data?.telephone?.length > 0 &&
                <>
                    <ItemClientPhoneIcon><FontAwesomeIcon icon={faPhone} size="1x"/></ItemClientPhoneIcon>
                    <ItemClientPhoneLabel>{data?.telephone || ''}</ItemClientPhoneLabel>
                </>}
                {data?.mobile !== "00.00.00.00.00" && data?.mobile?.length > 0 &&
                <>
                    <ItemClientMobileIcon><FontAwesomeIcon icon={faMobile} size="1x"/></ItemClientMobileIcon>
                    <ItemClientMobileLabel>{data?.mobile || ''}</ItemClientMobileLabel>
                </>}
                {data?.email?.length > 5 &&
                <>
                    <ItemClientEmailIcon><FontAwesomeIcon icon={faMailBulk} size="1x"/></ItemClientEmailIcon>
                    <ItemClientEmailLabel>{data?.email || ''}</ItemClientEmailLabel>
                </>}
                {user.idProfil === userProfiles.COMMERCIAL && user.idCommercial !== data?.idCommercial &&
                <>
                    <ItemClientWarningIcon><FontAwesomeIcon icon={faWarning} size="1x" color='red'/></ItemClientWarningIcon>
                    <ItemClientWarningLabel>Pour modifier les coordonnées de ce client vous devez vous rapprocher de votre responsable d'agence</ItemClientWarningLabel>
                </>}
                {user.idProfil === userProfiles.RESPONSABLE_AGENCE && user.idAgence !== data?.idAgence &&
                <>
                    <ItemClientWarningIcon><FontAwesomeIcon icon={faWarning} size="1x" color='red'/></ItemClientWarningIcon>
                    <ItemClientWarningLabel>Pour modifier les coordonnées de ce client vous devez vous rapprocher de votre responsable multi-agences</ItemClientWarningLabel>
                </>}
            </ItemClient>
        );
    }

    function ClientsList () {
        return (
            <>
            {clients?.length === 0 &&
                <ListContent>
                    <Alert variant="primary">
                        <Alert.Heading>Résultat de la recherche 😳</Alert.Heading>
                        <p>Il n'y a pas d'occurence trouvée !</p>
                    </Alert>
                </ListContent>
            }
            {clients?.length > 0 &&
                <ListContent>
                    {clients.map(function(item){
                        return <ClientOneItem data={item}/>
                    })}
                </ListContent>}
            </>
        );
    }

    return (
        <>
        <Menu />
        <Wrapper>
            <FormProvider {...methods}>
                <Form>
                    <Title>Ajouter un contact</Title>
                    <SubTitle>Étape 1/3</SubTitle>
                    <Form.Group className="mb-3" controlId="formBasicId">
                        <ModalFullScreen 
                            show={listClientShow}
                            title="Liste des clients recherchés"
                            content={<ClientsList />}
                            onClose={() => setListClientShow(false)}
                        />
                        <RSelect
                            refs={selectAgency}
                            id="agency"
                            name="agence"
                            label="Agence"
                            isDisabled={user?.idProfil === userProfiles.COMMERCIAL || user?.idProfil === userProfiles.RESPONSABLE_AGENCE}
                            defaultValue={(meeting?.current?.agence?.label?.length > 0) ? meeting?.current?.agence : {value: user?.idAgence, label: user?.nomAgence}}
                            data={agencies}
                            rules={{
                                required: 'Le nom de l\'agence est obligatoire',
                            }}
                            setFormError={setError}/>
                        <RSelect
                            refs={selectCommercial}
                            id="commercial"
                            name="commercial"
                            label="Commercial(e)"
                            isDisabled={user?.idProfil === 8}
                            defaultValue={(meeting?.current?.commercial?.label?.length > 0) ? meeting?.current?.commercial : {value: user?.idCommercial, label: user?.lastname + ' ' + user?.firstname}}
                            data={commercials}
                            rules={{
                                required: 'Le nom de commercial est obligatoire'
                            }}
                            setFormError={setError}/>
                        <Hline />
                        <RDate
                            refs={inputDate}
                            type="date"
                            id="date"
                            name="datePriseDeContact"
                            label="Prise contact"
                            defaultValue=""
                            rules={{
                                required: 'La date de prise de contact est obligatoire'
                            }}
                            setFormError={setError}/>
                        <Hline />
                        <RSelect
                            refs={selectTitle}
                            id="title"
                            name="titre"
                            label="Titre"
                            defaultValue={(meeting?.current?.titre?.label?.length > 0) ? meeting?.current?.titre : {value: 3, label: 'M OU MME'}}
                            data={titles}
                            setFormError={setError}/>
                        <RInput
                            refs={inputLastname}
                            type="text"
                            id="lastname"
                            name="nom"
                            label="Nom"
                            defaultValue=""
                            nbRecords={clients?.length || 0}
                            addList={clients?.length > 0}
                            onClickList={() => {
                                setListClientShow(true);
                            }}
                            onValueChanged={(e) => {
                                dispatch(getClients(e.target.value));
                            }}
                            rules={{
                                required: 'Le nom est obligatoire',
                                maxLength: {
                                    value: 50,
                                    message: "Le nom est limité à 50 caractères"
                                }
                            }}
                            setFormError={setError}/>
                        <RInput
                            refs={inputFirstname}
                            type="text"
                            id="firstname"
                            name="prenom"
                            label="Prénom"
                            defaultValue=""
                            rules={{
                                required: 'Le prénom est obligatoire',
                                maxLength: {
                                    value: 50,
                                    message: "Le prénom est limité à 50 caractères"
                                }
                            }}
                            setFormError={setError}/>
                        <RInput
                            refs={inputFirstname}
                            type="hidden"
                            id="note"
                            name="note"
                            defaultValue=""/>
                    </Form.Group>
                    <Submit value="Suivant" onClick={methods.handleSubmit(onSubmit, onError)}/>
                </Form>
            </FormProvider>
        </Wrapper>
        </>
    );
}

export default NewMeetingStepOne;