import React from 'react';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Form from 'react-bootstrap/Form';
import colors from '@Constants/colors';
import Menu from '@Menu';


const HeaderImage= styled.div`
    background-image: url(${process.env.REACT_APP_LOGO_PATH});
    width: 300px;
    height: 73px;
    margin-bottom: 10px;
    align-self: center;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
`;

const Div = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
`;

const Title = styled.h1`
    color: ${colors.BLACK};
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 10px 0px 10px 0px;
`;

const Hline = styled.div`
    width: 100%;
    height: 1px;
    background: ${colors.BLACK};
`;

const SubTitle = styled.h3`
    color: blue;
    font-size: 22px;
    font-weight: bold;
    margin: 0px 10px 10px 10px;
    cursor: pointer;
`;

function Documentation() {
    const navigate = useNavigate();

    return (
        <>
        <Menu />
            <Wrapper>
                <Div>
                    <HeaderImage />
                    <Title>Documentation</Title>
                    <SubTitle onClick={() => navigate("01-iPhone-location")}>Question 01 - Sur mon iPhone, comment activer la localisation automatique ?</SubTitle>
                </Div>
            </Wrapper>
        </>
    );
}

export default Documentation;


