import { useEffect } from 'react';
import {
    useRoutes,
    useLocation,
    Navigate
} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Home from '@Home';
import Map from '@Map';

import Documentation from '../doc';
import Doc01IPhoneLocation from '../doc/doc-01-iPhone-location';
import Maintenance from '../maintenance';
import Authentification from '@Auth';
import NewMeetingStepOne from '@NewMeeting/step01';
import NewMeetingStepTwo from '@NewMeeting/step02';
import NewMeetingStepThree from '@NewMeeting/step03';
import NewActivity from '../newActivity';
import { checkDeviceUUID } from '@Store/Device';
import { getApiInfo } from '@Store/ApiInfo';
import pck from "../../../package.json";
  
function RequireAuth({ children }) {

    //console.log("package oo", pck);
    const location = useLocation();
    const user = useSelector(state => state?.user);
  
    if (pck?.maintenance === true) {
      return (<Maintenance />);
    }
    return user?.authToken?.length > 0 ? (
      children
    ) : (
      <Navigate to="/login" replace state={{ path: location.pathname }} />
    );
}
  
function HandleRoutes() {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(checkDeviceUUID());
    dispatch(getApiInfo());
  }, []);

  let element = useRoutes([
      {path: '/', element: <Authentification />},
      {path: 'login', element: <Authentification />},
      {
          path: 'home',
          element:
            <RequireAuth>
              <Home />
            </RequireAuth>
      },
      {
          path: 'map',
          element:
            <RequireAuth>
              <Map />
            </RequireAuth>
      },
      {
        path: 'documentation',
        element:
          <RequireAuth>
            <Documentation />
          </RequireAuth>
      },
      
      {
        path: 'documentation/01-iPhone-location',
        element:
          <RequireAuth>
            <Doc01IPhoneLocation />
          </RequireAuth>
      },
      {
        path: 'newMeeting-step01',
        element:
          <RequireAuth>
            <NewMeetingStepOne />
          </RequireAuth>
      },
      {
        path: 'newMeeting-step02',
        element:
          <RequireAuth>
            <NewMeetingStepTwo />
          </RequireAuth>
      },
      {
        path: 'newMeeting-step03',
        element:
          <RequireAuth>
            <NewMeetingStepThree />
          </RequireAuth>
      },
      {
        path: 'newActivity',
        element:
          <RequireAuth>
            <NewActivity />
          </RequireAuth>
      }
    ]);
  
  return element;
}

export default HandleRoutes;