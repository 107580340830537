import colors from '@Constants/colors';

const clientStatus = [
    { 
        id: 0,
        name: 'me',
        label: 'Moi',
        color: colors.BLACK,
        textColor: colors.BLACK,
        iconMarker: './assets/map/marker-perso-32.png',
        checkColor: colors.TERTIARY,
        inFilter: false,
    },
    { 
        id: 1,
        name: 'propect',
        label: 'Prospect',
        color: '#DDDDDD60',
        textColor: colors.BLACK,
        iconMarker: './assets/map/marker-white-32.png',
        checkColor: colors.TERTIARY,
        inFilter: false,
    },
    { 
        id: 2,
        name: 'rOneAnnule',
        label: 'R1 annulé',
        color: '#FF999960',
        textColor: colors.BLACK,
        iconMarker: './assets/map/marker-pink-32.png',
        checkColor: colors.TERTIARY,
        inFilter: true,
    },
    { 
        id: 3,
        name: 'rOneConfirme',
        label: 'R1 confirmé',
        color: '#FF9B0060',
        textColor: colors.BLACK,
        iconMarker: './assets/map/marker-orange-32.png',
        checkColor: colors.TERTIARY,
        inFilter: false,
    },
    { 
        id: 4,
        name: 'renseigneEnAttente',
        label: 'Renseigné en attente',
        color: '#F6EF5060',
        textColor: colors.BLACK,
        iconMarker: './assets/map/marker-yellow-32.png',
        checkColor: colors.TERTIARY,
        inFilter: true,
    },
    {
        id: 5,
        name: 'renseigneVendu',
        label: 'Renseigné vendu',
        color: '#78CB4A60',
        textColor: colors.BLACK,
        iconMarker: './assets/map/marker-green-32.png',
        checkColor: colors.TERTIARY,
        inFilter: true,
    },
    {
        id: 6,
        name: 'renseignePerdu',
        label: 'Renseigné perdu', 
        color: '#EA332360',
        textColor: colors.BLACK,
        iconMarker: './assets/map/marker-red-32.png',
        checkColor: colors.TERTIARY,
        inFilter: true,
    },
    {
        id: 7, 
        name: 'annulationClient',
        label: 'Annulation client',
        color: '#12121260',
        textColor: colors.WHITE,
        iconMarker: './assets/map/marker-black-32.png',
        checkColor: colors.TERTIARY,
        inFilter: true,
    },
];

export default clientStatus;