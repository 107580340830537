import React from 'react';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import colors from '@Constants/colors';
import Menu from '@Menu';

const HeaderImage= styled.div`
    background-image: url(${process.env.REACT_APP_LOGO_PATH});
    width: 300px;
    height: 73px;
    margin-bottom: 10px;
    align-self: center;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    //margin-top: 100px;
`;

const Div = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
`;

const Title = styled.h1`
    color: ${colors.BLACK};
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 10px 0px 10px 0px;
`;

const Text = styled.h6`
    color: ${colors.GREY};
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0px 10px 0px;
`;

const Hline = styled.div`
    width: 100%;
    height: 1px;
    background: ${colors.BLACK};
`;

const SubTitle = styled.h3`
    color: blue;
    font-size: 22px;
    font-weight: bold;
    margin: 0px 10px 10px 10px;
    cursor: pointer;
`;

const Image = styled.img`
    max-width: 100%;  
    height: auto;
    margin-bottom: 10px;
    align-self: center;
`;

function Doc01IPhoneLocation() {
    const navigate = useNavigate();

    return (
        <>
        <Menu />
            <Wrapper>
                <Div>
                    <HeaderImage />
                    <Title>Sur mon iPhone, comment activer la localisation automatique ?</Title>
                    <Text>Étape 01 - Sur l'iPhone, dans "Réglages", cherchez "Safari" puis cliquez dessus (cf. image ci-dessous)</Text>
                    <Image src="./assets/doc/iOS_Safari_Doc_01.avif" />
                    <Text>Étape 02 - Dans Safari, scrollez jusqu'à "Position" puis cliquez dessus (cf. image ci-dessous)</Text>
                    <Image src="./assets/doc/iOS_Safari_Doc_02.avif" />
                    <Text>Étape 03 - Puis cliquez sur "Demander" (cf. image ci-dessous)</Text>
                    <Image src="./assets/doc/iOS_Safari_Doc_03.avif" />
                    <Text>Étape 04 - Revenir sur la page principale de "Réglages", et tapez dans la barre de recherche "Loca..." (cf. image ci-dessous)</Text>
                    <Image src="./assets/doc/iOS_Safari_Doc_04.avif" />
                    <Text>Étape 05 - Puis cliquez sur "Localisation", et cochez l'option "Service de localisation" (cf. image ci-dessous)</Text>
                    <Image src="./assets/doc/iOS_Safari_Doc_05.avif" />
                    <Text>Étape 06 - Scrollez dans la liste proposée pour trouver "Sites Safari" et cliquez dessus (cf. image ci-dessous)</Text>
                    <Image src="./assets/doc/iOS_Safari_Doc_06.avif" />
                    <Text>Étape 07 - Assurez-vous que les réglages ci-dessous soient sélectionnés.</Text>
                    <Image src="./assets/doc/iOS_Safari_Doc_07.avif" />
                    <Text>Étape 08 - Enfin, lancez l'application Safari, puis allez dans l'application CRM, puis "Cartographie".</Text>
                    <Text>Automatiquement Safari vous demande d'autoriser ou non le partage de localisation, cliquez sur "Autoriser" (cf. image ci-dessous)</Text>
                    <Image src="./assets/doc/iOS_Safari_Doc_08.avif" />
                    <Text>Enjoy it! 😉 (cf. image ci-dessous)</Text>
                    <Image src="./assets/doc/iOS_Safari_Doc_End.avif" />
                </Div>
            </Wrapper>
        </>
    );
}

export default Doc01IPhoneLocation;


