import axios from '@Services/axios';

export const MARKER_GET_LOADING = 'marker/MARKER_GET_LOADING';
export const MARKER_GET_SUCCESS = 'marker/MARKER_GET_SUCCESS';
export const MARKER_GET_ERROR = 'marker/MARKER_GET_ERROR';


const initialState = {
    markers: [],
    
    isGetInProgress: false,
    isGetComplete: false,
    
    error: ''
};

export function reduxThunkReducer(state = initialState, action) {
    switch (action.type) {
        
        case MARKER_GET_LOADING: {
            return {
                ...state,
                isGetInProgress: true,
                isGetComplete: false,
                error: '',
            };
        }
        case MARKER_GET_SUCCESS: {
            return {
                ...state,
                isGetInProgress: false,
                isGetComplete: true,
                markers: action?.markers || [],
            };
        }
        case MARKER_GET_ERROR: {
            return {
                ...state,
                isGetInProgress: false,
                isGetComplete: false,
                error: action?.error || '',
            };
        }
        default: {
            return {...state};
        }
    }
}

////////////////////////////////////////////////////////////////////
const URL_API = process.env.REACT_APP_API_ENDPOINT;

export const getMarkers = (radius, latitude, longitude, filter=null) => {
  return (dispatch, getState) => {
    const state = getState();
    if (!state?.user?.authToken || state.user.authToken.length === 0 ||
        radius === 0 || latitude === 0 || longitude === 0) {
      dispatch({ 
        type: MARKER_GET_ERROR,
        error: 'PREPARATION URL = Il manque des paramètres'
      });
    } else {
      dispatch({ 
        type: MARKER_GET_LOADING
      });
      const dataRequest = {
        rayon: radius,
        latitude: latitude,
        longitude: longitude,
        
      };
      if (filter) {
        dataRequest.filtres = filter;
      }
      axios.post(`${URL_API}/listeClients`, dataRequest).then(
        response => {
          console.log("response LISTE CLIENT", response?.data?.données);

          if (response?.data?.données) {
            dispatch({ 
              type: MARKER_GET_SUCCESS,
              markers: response?.data?.données || []
            });
          } else {
            dispatch({ type: MARKER_GET_ERROR, error: "Erreur lors de la récupération de la liste des clients" });
          }
      },
      error => {
        dispatch({ type: MARKER_GET_ERROR, error: error?.response?.data });
      });
      
    }
  };
};

