import axios from '@Services/axios';

export const CITY_GET_LOADING = 'city/CITY_GET_LOADING';
export const CITY_GET_SUCCESS = 'city/CITY_GET_SUCCESS';
export const CITY_GET_ERROR = 'city/CITY_GET_ERROR';

const initialState = {
    cities: [],
    
    isGetInProgress: false,
    isGetComplete: false,
    
    error: ''
};

export function reduxThunkReducer(state = initialState, action) {
    switch (action.type) {
        
        case CITY_GET_LOADING: {
            return {
                ...state,
                isGetInProgress: true,
                isGetComplete: false,
                error: '',
                cities: []
            };
        }
        case CITY_GET_SUCCESS: {
            return {
                ...state,
                isGetInProgress: false,
                isGetComplete: true,
                cities: action?.cities || [],
            };
        }
        case CITY_GET_ERROR: {
            return {
                ...state,
                isGetInProgress: false,
                isGetComplete: false,
                error: action?.error || '',
            };
        }
        default: {
            return {...state};
        }
    }
}

////////////////////////////////////////////////////////////////////
const URL_API = process.env.REACT_APP_API_ENDPOINT;

export const getCities = (zip) => {
  return (dispatch, getState) => {
    const state = getState();
    
    dispatch({ 
      type: CITY_GET_LOADING
    });
    if (!zip || zip.length < 5) {
      return;
  }
    axios.post(`${URL_API}/listeVilles`,
      {
        codePostal: zip || ''
      }).then(
      response => {
        console.log("response LISTE VILLES", response?.data?.données);

        if (response?.data?.données) {
          const data = response?.data?.données;
          const cities = [];
          data.map((onecity, index) => {
            cities.push({value: index || 0, label: onecity|| ''});
          });
          dispatch({ 
            type: CITY_GET_SUCCESS,
            cities: cities
          });
        } else {
          dispatch({ type: CITY_GET_ERROR, error: "Erreur lors de la récupération de la liste des villes" });
        }
    },
    error => {
      dispatch({ type: CITY_GET_ERROR, error: error?.response?.data });
    });
  };
};

