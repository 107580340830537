import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import RInput from '@Components/form/input';
import styled from "styled-components";
import { login } from '@Store/User';
import pck from "../../../package.json";
import colors from '@Constants/colors';

import Form from 'react-bootstrap/Form';
import AlertError from '@Components/alertError';
import Maintenance from '../maintenance';

const HeaderImage= styled.div`
    background-image: url(${process.env.REACT_APP_LOGO_PATH});
    width: 300px;
    height: 73px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
`;

const Header = styled.h1`
    color: ${colors.BLACK};
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 10px 0px 25px 0px;
`;

const ModeEnv = styled.h4`
    color: grey;
    font-size: 10px;
    font-weight: bold;
    margin: 20px 10px 10px 10px;
`;

const VersionText = styled.h3`
    color: grey;
    font-size: 14px;
    font-weight: bold;
    margin: 20px 10px 30px 10px;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
`;

const Submit = styled.input.attrs({ type: 'submit' })`
  background: ${colors.SECONDARY};
  color: ${colors.WHITE};
  cursor: pointer;
  margin-bottom: 0;
  font-weight: bold;
  text-transform: uppercase;
  width: 350px;
  border-radius: 5px;
  height: 38px;
  border-color: transparent;
  box-shadow: 0px;
  outline: none;
  transition: 0.15s;
  text-align: center;
  text-transform: uppercase;
  &:active {
    background-color: ${colors.TERTIARY};
  }
`;

function Authentification() {
    const dispatch = useDispatch();
    const [setError] = useState(false);
    const { state } = useLocation();
    const navigate = useNavigate();

    const inputLogin = useRef(null);
    
    const {...methods} = useForm({mode: 'onChange'});

    const user = useSelector(state => state?.user);

    useEffect(() => {
        if (!pck?.maintenance) {
            inputLogin.current.focus();
        }
    }, []);

    useEffect(() => {
        if (!pck?.maintenance && user?.authToken?.length > 0 && user.isLoginComplete && !user.isLoginInProgress) {
            navigate("/home");
        }
    }, [user]);

    const onSubmit = (data) => {
        dispatch(login(data?.login?.toUpperCase(), data?.pwd));
    }

    const onError = (errors, e) => {
        return console.log({errors});
    }

    console.log("pck", pck);

    return (
        <Wrapper>
            {pck?.maintenance && <Maintenance />}
            {!pck?.maintenance && <FormProvider {...methods}>
                    <Form>
                        <HeaderImage />
                        <Header>espace connecté</Header>
                        {process.env.REACT_APP_MODE !== 'Mode production' && <ModeEnv>{process.env.REACT_APP_MODE}</ModeEnv>}
                        {<VersionText>{pck?.version?.length > 0 ? `Version ${pck?.version}` : ``}</VersionText>}
                        {!user?.isLoginInProgress && !user?.isLoginComplete && user?.error?.length > 0 &&
                            <AlertError message={user?.error} show={user?.error?.length > 0}/>}
                        <Form.Group className="mb-3" controlId="formBasicId">
                            <RInput
                                refs={inputLogin}
                                type="text"
                                id="login"
                                name="login"
                                label="Identifiant"
                                defaultValue=""
                                rules={{
                                    required: 'L\'identifiant est obligatoire'
                                }}
                                setFormError={setError}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <RInput
                                type="password"
                                id="password"
                                name="pwd"
                                label="Mot de passe"
                                defaultValue=""
                                rules={{
                                    required: 'Le mot de passe est obligatoire'
                                }}
                                setFormError={setError}/>
                        </Form.Group>
                        <Submit value="se connecter" onClick={methods.handleSubmit(onSubmit, onError)} />
                    </Form>
                </FormProvider>}
        </Wrapper>
    );
}

export default Authentification;


