import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { logout } from '@Store/User';
import styled from "styled-components";
import pck from "../../../package.json";
import Form from 'react-bootstrap/Form';

import { FaUserPlus } from "react-icons/fa";
import { FaBookOpen } from "react-icons/fa";
import { TbMapPins } from "react-icons/tb";
import { BiLogOut } from "react-icons/bi";
import { BiListPlus } from 'react-icons/bi';
import colors from '@Constants/colors';


const HeaderImage= styled.div`
    background-image: url(${process.env.REACT_APP_LOGO_PATH});
    width: 305px;
    height: 73px;
    background-repeat: no-repeat;
    margin-inline: auto;
`;

const Title = styled.h1`
    color: ${colors.BLACK};
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 10px 0px 50px 0px;
`;

const ModeEnv = styled.h4`
    color: grey;
    font-size: 10px;
    font-weight: bold;
    margin: 20px 10px 10px 10px;
`;

const VersionText = styled.h3`
    color: grey;
    font-size: 14px;
    font-weight: bold;
    margin: 20px 10px 30px 10px;
`;

const Div = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
`;

const IconAddUser = styled(FaUserPlus)`
    margin-bottom: 5px;
    color: ${colors.WHITE};
`;

const IconAddTask = styled(BiListPlus)`
    margin-bottom: 5px;
    color: ${colors.WHITE};
`;

const IconMap = styled(TbMapPins)`
    margin-bottom: 5px;
    color: ${colors.WHITE};
`;

const IconBook = styled(FaBookOpen)`
    margin-bottom: 5px;
    color: ${colors.BLACK};
`;

const IconLogout = styled(BiLogOut)`
    margin-bottom: 5px;
    color: ${colors.WHITE};
`;

const ButtonPurple = styled.div`
    width: 300px;
    border-width: 1px;
    border-color: ${colors.BLACK};
    border-style: solid;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    color: ${colors.WHITE};
    background-color: ${colors.PRIMARY};
`;

const ButtonSecondary = styled.div`
    width: 300px;
    border-width: 1px;
    border-color: ${colors.BLACK};
    border-style: solid;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    color: ${colors.BLACK};
    background-color: ${colors.SECONDARY};
`;

const ButtonRed = styled.div`
    width: 300px;
    border-width: 2px;
    border-color: ${colors.GREY};
    border-style: solid;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    color: ${colors.WHITE};
    background-color: ${colors.RED};
`;

const List = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
`;

function Home() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state?.user);
    
    return (
        <>
            <Wrapper>
                <Div>
                    <HeaderImage />
                    {process.env.REACT_APP_MODE !== 'Mode production' && <ModeEnv>{process.env.REACT_APP_MODE}</ModeEnv>}
                    {<VersionText>{pck?.version?.length > 0 ? `Version ${pck?.version}` : ``}</VersionText>}
                        
                    <Title>{`Bienvenue ${user?.firstname || ''} ${user?.lastname || ''} !`}</Title>
                    <Form.Group className="mb-3" controlId="formBasicId">
                        <List>
                            <ButtonPurple onClick={() => navigate("/map")}><IconMap size={35}/>{'  '}Cartographie</ButtonPurple>
                            <ButtonPurple onClick={() => navigate("/newMeeting-step01")}><IconAddUser size={35}/>{'  '}Ajouter un contact</ButtonPurple>
                            {/*<ButtonPurple onClick={() => navigate("/newActivity")}><IconAddTask size={35}/>{'  '}Saisir une activité</ButtonPurple>*/}
                            <ButtonSecondary onClick={() => navigate("/documentation")}><IconBook size={35}/>{'  '}Documentation</ButtonSecondary>
                            <ButtonRed onClick={() => dispatch(logout())}><IconLogout size={35}/>{'  '}Se déconnecter</ButtonRed>
                        </List>
                    </Form.Group>
                    </Div>
            </Wrapper>
        </>
    );
}

export default Home;


