import axios from 'axios';
import { logout } from '@Store/User';

const URL_API = process.env.REACT_APP_API_ENDPOINT_AUTH;
const TIMEOUT = process.env.REACT_APP_API_TIMEOUT;

const instance = axios.create({
    baseURL: `${URL_API}`,
    timeout: 1000 * TIMEOUT,      // Timeout de 20/45 secondes (HOMKIA/3GR)
    headers: {
        'Content-Type': 'application/json'
    }
});

let store = undefined;
export const injectStore = _store => {
    store = _store;
    console.log("store :", store);
}

instance.interceptors.request.use(
    config => {

        const state = store.getState();
        console.log("REQUEST STORE ==>", store);

        console.log("REQUEST URL WS ==>", config?.url);
        console.log("REQUEST METHOD ==>", config?.method?.toUpperCase());
        console.log("REQUEST HEADERS ==>", config?.headers);

        if (state?.user?.authToken?.length > 0) {
            config.data.token = state.user.authToken;
        }
        
        if (state?.device?.deviceUUID?.length > 0) {
            config.data.deviceUUID = state.device.deviceUUID;
        }

        console.log("REQUEST DATA ==>", config?.data);

        if (state?.user?.authToken?.length > 0) {
            console.log("REQUEST TOKEN ==>", state?.user?.authToken);
            config.headers["Authorization"] = `${state?.user?.authToken}`;
        }
        return config;
    },
    error => {
  
        if (error?.message === "Network Error" && error?.name === "Error") {
            console.error('Il se pourrait que votre connexion vers internet ne fonctionne pas.');
        }
    
        console.error("REQUEST ERROR ==>", JSON.stringify(error));
        return Promise.reject(error);
    },
);
  
instance.interceptors.response.use(
     response => {
        console.log("RESPONSE URL WS ==>", response?.config?.url);
        console.log("RESPONSE METHOD ==>", response?.config?.method?.toUpperCase());
        console.log("RESPONSE DATA ==>", response?.data);
        console.log("RESPONSE HEADER ==>", response?.config?.headers?.Authorization);
        return response;
    },
    error => {
        
        console.log("RESPONSE ERROR ==>", error?.response);
        console.log("RESPONSE ERROR URL ==>", error?.response?.config?.url);
        console.log("RESPONSE ERROR STATUS ==>", error?.response?.status);
        console.log("RESPONSE ERROR STATUS TEXT ==>", error?.response?.statusText);
        console.log("RESPONSE ERROR DATA ==>", error?.response?.data);

        const state = store.getState();
        const dispatch = store.dispatch;

        if (error?.message === "Network Error" && error?.name === "Error") {
            console.error('Il se pourrait que votre connexion vers internet ne fonctionne pas.');
            return Promise.reject(error);
        } else if (error?.response?.status === 401 || error?.response?.status === 403) {
            console.error('Il se peut que l\'identifiant et le mot de passe ne soient pas corrects.');
            console.log(window.location.pathname);
            if (state?.user?.authToken?.length > 0) {
                dispatch(logout());
            }
        } else {
            console.error(error?.data);
        }
        return Promise.reject(error || 'Erreur reçue du serveur');
    },
);

export default instance;
