import { legacy_createStore as createStore, applyMiddleware, combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { injectStore } from '../services/axios';

import { reduxThunkReducer as UserReducers } from './User';
import { reduxThunkReducer as MarkerReducers } from './Marker';
import { reduxThunkReducer as DeviceReducers } from './Device';
import { reduxThunkReducer as ApiInfoReducers } from './ApiInfo';

import { reduxThunkReducer as FamilyReducers } from './Family';
import { reduxThunkReducer as TitleReducers } from './Title';
import { reduxThunkReducer as AgencyReducers } from './Agency';
import { reduxThunkReducer as SourceReducers } from './Source';
import { reduxThunkReducer as MeetingReducers } from './Meeting';
import { reduxThunkReducer as ActivityReducers } from './Activity';
import { reduxThunkReducer as HistoryReducers } from './Historic';
import { reduxThunkReducer as CommercialReducers } from './Commercial';
import { reduxThunkReducer as ClientReducers } from './Client';
import { reduxThunkReducer as CityReducers } from './City';


const userPersistConfig = {
  key: 'user',
  storage: storage
};

const devicePersistConfig = {
  key: 'device',
  storage: storage
};

const historicPersistConfig = {
  key: 'geoHistoric',
  storage: storage
};

const reducer = combineReducers({
  user: persistReducer(userPersistConfig, UserReducers),
  marker: MarkerReducers,
  device: persistReducer(devicePersistConfig, DeviceReducers),
  apiInfo: ApiInfoReducers,
  family: FamilyReducers,
  title: TitleReducers,
  agency: AgencyReducers,
  source: SourceReducers,
  meeting: MeetingReducers,
  activity: ActivityReducers,
  history: persistReducer(historicPersistConfig, HistoryReducers),
  commercial: CommercialReducers,
  client: ClientReducers,
  city: CityReducers
});
const middleware = applyMiddleware(thunk, logger);

const store = createStore(
  reducer,
  middleware,
);
injectStore(store);
const persistor = persistStore(store);

export { store, persistor };

