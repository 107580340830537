import { useController, useFormContext } from 'react-hook-form';
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import Badge from 'react-bootstrap/Badge';
import colors from '@Constants/colors';

const Div = styled.div`
  position: relative;
  margin-bottom: 20px;
  margin-top: 10px;
`;

const Wrapper = styled.div`
  display: flex;
`;

const Badger = styled(Badge)`
  position: absolute;
  right: -6px;
  top: -10px;
  color: ${colors.WHITE};
`;

const CustomInput = styled.input.attrs(() => ({
  required: true
}))`
  font-size: 16px;
  padding: 7px 10px 10px 5px;
  display: block;
  width: ${props => props.addList?'310px':'350px'};
  height: 38px;
  border: 1px solid ${colors.GREY};
  border-radius: 5px;
  &:focus {
    border-color: ${colors.TERTIARY};
    border-width: 2px;
    outline: none;
  }
`;

const IconAction = styled.div`
  width: 38px;
  height: 38px;
  border: 1px solid ${colors.GREY};
  border-radius: 5px;
  margin-left: 2px;
  &:focus {
    border-color: ${colors.TERTIARY};
    border-width: 2px;
    outline: none;
  }
`;

const FontAwesome = styled(FontAwesomeIcon)`
  color: grey;
  padding-top: 2px;
`;

const Label = styled.label`
  color: ${colors.GREY};
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 6px;
  transition: 0.2s ease all;
  input:focus ~ &,
  input:not(:placeholder-shown).input:not(:focus) ~ .label,
  input:valid ~ & {
    top: -20px;
    font-size: 14px;
    color: ${colors.PRIMARY};
    font-weight: bold;
  }
`;

const SpanError = styled.div`
  color: ${colors.RED};
  font-size: 14px;
  font-weight: normal;
  text-align: left;
`;


function ControlledInput(props) {
    const formContext = useFormContext();
    const { formState } = formContext;

    const {
        id,
        name,
        label,
        rules,
        defaultValue,
        type,
        refs,
        addList,
        onClickList,
        onValueChanged,
        nbRecords
        } = props;

    const { field } = useController({ name, rules, defaultValue });

    const hasError = Boolean(formState?.errors[name]);
    return (
      <Div>
        <Wrapper>
          <CustomInput
            type={type}
            id={id}
            name={name}
            addList={addList}
            value={field?.value || defaultValue}
            onChange={(e) => {
              field.onChange(e);
              if (onValueChanged) {
                onValueChanged(e);
              }
            }}
            ref={refs}
          />
          {addList &&
          <IconAction onClick={onClickList}>
            <FontAwesome
                icon={faEllipsisH}
                size="2x"/>
            <Badger pill bg={nbRecords===0?"danger":"success"}>
              {nbRecords || 0}
            </Badger>
          </IconAction>}
          {label?.length > 0 &&
            <Label htmlFor={name}>{label}</Label>}
        </Wrapper>
          
        {hasError && (<SpanError>{formState.errors[name].message || ''}</SpanError>)}
      </Div>
    );
}

function RInput(props) {

  const {
    name,
    setFormError
  } = props;

  const formContext = useFormContext();

  // Placeholder until input name is initialized
  if (!formContext || !name) {
    //const msg = !formContext ? "TextInput must be wrapped by the FormProvider" : "Name must be defined";
    setFormError(true);
    return null;
  }

  return <ControlledInput {...props} />;

};

export default RInput;