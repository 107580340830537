import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

function Toast() {
    return (
      <>
        <ToastContainer />
      </>
    );
}

export default Toast;