import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import styled from "styled-components";
import colors from '@Constants/colors';

const Icon = styled(Button)`
  background-color: ${colors.WHITE};
  border-color: ${colors.TERTIARY};
  border-radius: 50%;
  position: absolute;
  bottom: 140px;
  right: 15px;
  z-index:20000;
  width: 55px;
  padding: 15px 0;

  &:hover{
    border-color: ${colors.TERTIARY};
    background-color: ${colors.TERTIARY};
  }

`;

const LocationIcon = styled(FontAwesomeIcon)`
  color: ${colors.TERTIARY};
  
  &:hover{
    color: ${colors.WHITE};
  }
`;

function RoundIconGeo({show, click}) {

  const ChangeOnHover =()=>{
    document.getElementById('RoundIconGeo').style.color = colors.WHITE;
  }
  const ChangeOnOut =()=>{
    document.getElementById('RoundIconGeo').style.color = colors.TERTIARY;
  }

  return (
    <>
        {show &&
        <Icon onMouseOver={ChangeOnHover} onMouseOut={ChangeOnOut} onClick={click}>
          <LocationIcon id="RoundIconGeo" icon={faLocationArrow} size="xl"/>
        </Icon>}
    </>
  );
}

export default RoundIconGeo;